import React, { useState } from "react";
import PropTypes from "prop-types";

import RoleMenuTable from "./role-menu-table.jsx";
import AddNodeDialog from "./add-node-dialog.jsx";
import SelectRoleDialog from "./select-role-dialog.jsx";
import CopyMenuDialog from "./copy-menu-dialog.jsx";
import AddFolderDialog from "./add-folder-dialog.jsx";

import "./styles.less";

const ROLE_MENU_DIALOGS = {
    ADD_NODE: "ADD_NODE",
    SELECT_ROLE: "SELECT_ROLE",
    COPY_MENU: "COPY_MENU",
    ADD_FOLDER: "ADD_FOLDER"
};

const RoleMenu = ({ menuTree, systemId, onOpenNode, onAddFolder, onAddNode, onRemoveNode, onCopyMenu }) => {
    const [activeNodePath, setActiveNodePath] = useState(menuTree[0].path);
    const [dialog, setDialog] = useState(null);

    const handleAddFolder = (e) => {
        e.stopPropagation();
        setDialog({ type: ROLE_MENU_DIALOGS.ADD_FOLDER });
    };

    const handleDialogAddFolder = (folder) => {
        onAddFolder(activeNodePath, folder);
        setDialog(null);
    };

    const handleRemoveNode = (e) => {
        e.stopPropagation();

        if (activeNodePath && activeNodePath === menuTree[0].path) {
            return;
        }

        onRemoveNode(activeNodePath);
        setActiveNodePath(menuTree[0].path);
    };

    const handleAddNode = (e) => {
        e.stopPropagation();
        setDialog({ type: ROLE_MENU_DIALOGS.ADD_NODE });
    };

    const handleCopy = (e) => {
        e.stopPropagation();
        setDialog({ type: ROLE_MENU_DIALOGS.SELECT_ROLE });
    };

    const handleDialogAddNode = (tcodes) => {
        onAddNode(activeNodePath, tcodes);
        setDialog(null);
    };

    const handleDialogSelectRole = (role) => {
        setDialog({
            type: ROLE_MENU_DIALOGS.COPY_MENU,
            role
        });
    };

    const handleDialogCopyMenu = (checkedItems) => {
        onCopyMenu(checkedItems);
        setDialog(null);
    };

    const handleCloseDialog = () => setDialog(null);

    const addNodeDialogOpen = dialog?.type === ROLE_MENU_DIALOGS.ADD_NODE;
    const selectRoleDialogOpen = dialog?.type === ROLE_MENU_DIALOGS.SELECT_ROLE;
    const copyMenuDialogOpen = dialog?.type === ROLE_MENU_DIALOGS.COPY_MENU;
    const addFolderDialogOpen = dialog?.type === ROLE_MENU_DIALOGS.ADD_FOLDER;

    return (
        <>
            <RoleMenuTable
                tree={menuTree}
                activeNodePath={activeNodePath}
                onRowClick={(row) => setActiveNodePath(row.path)}
                onAddFolder={handleAddFolder}
                onAddNode={handleAddNode}
                onRemoveNode={handleRemoveNode}
                onCopy={handleCopy}
                onOpenNode={onOpenNode}
            />

            {addNodeDialogOpen && (
                <AddNodeDialog
                    open={addNodeDialogOpen}
                    systemId={systemId}
                    onClose={handleCloseDialog}
                    onAdd={handleDialogAddNode}
                />
            )}

            {selectRoleDialogOpen && (
                <SelectRoleDialog
                    open={selectRoleDialogOpen}
                    systemId={systemId}
                    onClose={handleCloseDialog}
                    onSelect={handleDialogSelectRole}
                />
            )}

            {copyMenuDialogOpen && (
                <CopyMenuDialog
                    open={copyMenuDialogOpen}
                    role={dialog.role}
                    systemId={systemId}
                    onClose={handleCloseDialog}
                    onSubmit={handleDialogCopyMenu}
                />
            )}

            {addFolderDialogOpen && (
                <AddFolderDialog
                    open={addFolderDialogOpen}
                    onClose={handleCloseDialog}
                    onAdd={handleDialogAddFolder}
                />
            )}
        </>
    );
};

RoleMenu.propTypes = {
    menuTree: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.string,
    onOpenNode: PropTypes.func,
    onAddFolder: PropTypes.func,
    onAddNode: PropTypes.func,
    onRemoveNode: PropTypes.func,
    onCopyMenu: PropTypes.func
};

export default RoleMenu;