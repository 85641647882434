import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, Box, CircularProgress, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { integrationService } from "../../../services/integration-service";

import { getCheckedByEntitySAP } from "../../../utils/integration-utils";

import { PageContentBox } from "../../common/page-content-box";
import { SelectInput, CheckboxInput, TimePicker, DatePicker, TextInput, FormHelperText } from '../../common/form-controls';
import { EntityFormHeader, EntityFormHeaderTitle, ADD_MODE, EDIT_MODE, READ_MODE } from "../../common/entity-form";
import { MenuItem } from "../../common/menu";
import { Switch } from "../../common/buttons";
import { SaveButton } from "../../common/entity-form/entity-form-actions.jsx";
import { ErrorsDialog } from "../../common/dialogs";
import breakpoints from "../../common/styles/breakpoints.js";

import "./styles.less"

const HeaderTitle = ({ system, mode, onBackClick, onSave }) => {
    const intl = useIntl();
    const titleValues = { system };

    const titleMap = {
        [ADD_MODE]: intl.formatMessage({ id: "integration-schedule-page.title.add" }, titleValues),
        [EDIT_MODE]: intl.formatMessage({ id: "integration-schedule-page.title.edit" }, titleValues),
        [READ_MODE]: intl.formatMessage({ id: "integration-schedule-page.title.read" }, titleValues),
    };

    return (
        <EntityFormHeader>
            <EntityFormHeaderTitle
                title={titleMap[mode]}
                onBackClick={onBackClick}
            />

            <SaveButton onClick={onSave} />
        </EntityFormHeader>
    );
};

const CustomPeriodField = ({
    periodValue,
    customPeriod,
    setPeriodValue,
    setCustomPeriod,
    hidden,
    error
}) => {
    const intl = useIntl();

    const wrapperClassName = hidden
        ? "custom-period-input-wrapper custom-period-input-wrapper-hidden"
        : "custom-period-input-wrapper";

    const customPeriodicValues = useMemo(() => [{
        key: "hours",
        text: intl.formatMessage({id: "integration-schedule-page.time.custom.hours.text"})
    }, {
        key: "weeks",
        text: intl.formatMessage({id: "integration-schedule-page.time.custom.weeks.text"})
    }, {
        key: "months",
        text: intl.formatMessage({id: "integration-schedule-page.time.custom.months.text"})
    }], [intl])

    return (
        <Box className={wrapperClassName}>
            <TextInput
                className="custom-period-input"
                label={intl.formatMessage({ id: "integration-planning.custom-periodic-field.title" })}
                value={periodValue}
                error={Boolean(error)}
                errorMessage={error?.message}
                onChange={(e) => {
                    const value = e.target.value;
    
                    if (isNaN(new Number(value))) {
                        return
                    }
    
                    setPeriodValue(value)
                }}
            />

            <SelectInput
                className="custom-period-input-select"
                value={customPeriod}
                onChange={(event) => setCustomPeriod(event.target.value)}
            >
                {customPeriodicValues.map(item => (
                    <MenuItem
                        value={item.key}
                        key={item.key}
                        sx={{
                            minHeight: "initial",
                            height: "36px"
                        }}
                    >
                        {item.text}
                    </MenuItem>
                ))}
            </SelectInput>
        </Box>
    );
};

const PeriodField = ({ value, setValue, disabled }) => {
    const intl = useIntl();

    const periodicValues = useMemo(() => [{
        key: "single",
        text: intl.formatMessage({id: "integration-schedule-page.time.single.text"})
    }, {
        key: "days",
        text: intl.formatMessage({id: "integration-schedule-page.time.days.text"})
    }, {
        key: "months",
        text: intl.formatMessage({id: "integration-schedule-page.time.months.text"})
    }, {
        key: "half-year",
        text: intl.formatMessage({id: "integration-schedule-page.time.half-year.text"})
    }, {
        key: "custom",
        text: intl.formatMessage({id: "integration-schedule-page.time.custom.text"})
    }], [intl])

    return (
        <SelectInput
            label={intl.formatMessage({ id: "integration-planning.periodic-field.title" })}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            disabled={disabled}
        >
            {periodicValues.map(item => (
                <MenuItem
                    value={item.key}
                    key={item.key}
                    sx={{
                        minHeight: "initial",
                        height: "36px"
                    }}
                >
                    {item.text}
                </MenuItem>
            ))}
        </SelectInput>
    );
};

const FirstStartDateField = ({ date, onChange, error }) => {
    const intl = useIntl();

    const errorDate = error && error.value ? new Date(error.value) : null;
    const hasError = errorDate && date && errorDate.getTime() === date.getTime();

    return (
        <Box className="integration-schedule-first-start-date-wrapper">
            <DatePicker
                label={intl.formatMessage({ id: "integration-planning.first-start-datetime.title" })}
                value={date}
                error={hasError}
                onChange={onChange}
            />

            <TimePicker
                value={date}
                error={hasError}
                onChange={onChange}
            />

            {error && (
                <FormHelperText
                    error={hasError}
                    errorMessage={error?.message}
                />
            )}
        </Box>
    )
}

const ObjectPermissionParamItem = ({ label, parameters, setParameter }) => {
    return (
        <>
            <Typography className="integration-schedule-page-permission-title">
                {label}
            </Typography>

            <Switch
                checked={parameters.delta}
                className="hidden"
                onChange={() => {
                    setParameter("delta", !parameters.delta)
                }}
            />
        </>
    )
}

const ObjectRepositoryParamItem = ({ label, parameters, setParameter }) => {
    return (
        <>
            <CheckboxInput
                sx={{
                    paddingLeft: "10px"
                }}
                checked={parameters.checked}
                onChange={() => {
                    setParameter("checked", !parameters.checked)
                }}
                label={label}
            />

            <Switch
                checked={parameters.delta}
                className="hidden"
                onChange={() => {
                    setParameter("delta", !parameters.delta)
                }}
            />
        </>
    )
}

const IntegrationPramatersInput = ({ form, setForm, error }) => {
    const intl = useIntl();
    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const setParam = (entity) => (field, value) => setForm(form => {
        const newForm = {
            ...form,
            params: {
                ...form.params,
                [entity]: {
                    ...form.params[entity],
                    [field]: value
                }
            }
        };

        if (field !== "checked" || !["profile", "role", "employee"].includes(entity)) {
            return newForm;
        }

        const updatedChecked = getCheckedByEntitySAP(
            {
                profile: newForm.params.profile.checked,
                role: newForm.params.role.checked,
                employee: newForm.params.employee.checked,
            },
            entity
        );

        newForm.params.profile.checked = updatedChecked.profile;
        newForm.params.role.checked = updatedChecked.role;
        newForm.params.employee.checked = updatedChecked.employee;

        return newForm;
    });

    return (
        <Box className="integration-schedule-parameters-wrapper">
            <Typography className="integration-schedule-page-column-title">
                <FormattedMessage id="integration-schedule-page.variant.title" />
            </Typography>

            <FormHelperText
                error={Boolean(error)}
                errorMessage={error?.message}
            />

            <Typography className="integration-schedule-page-column-title hidden">
                <FormattedMessage id="integration-schedule-page.variant.delta"/>
            </Typography>

            {form.type === "permission_details" && (
                matches1024 ? (
                    <ObjectPermissionParamItem
                        label={intl.formatMessage({ id: "integration-schedule-page.entity.permission-details.title" })}
                        parameters={form.params.permissionDetails}
                        setParameter={setParam("permissionDetails")}
                    />
                ) :
                    <Box className="integration-schedule-page-permission-wrapper">
                        <ObjectPermissionParamItem
                            label={intl.formatMessage({ id: "integration-schedule-page.entity.permission-details.title" })}
                            parameters={form.params.permissionDetails}
                            setParameter={setParam("permissionDetails")}
                        />
                    </Box>
            )}

            {form.type === "repository_objects" && (
                <>
                    <Typography className="integration-schedule-page-entity-header-title">
                        <FormattedMessage id="integration-schedule-page.entity.repository-objects.title" />
                    </Typography>

                    <ObjectRepositoryParamItem
                        label={intl.formatMessage({ id: "integration-schedule-page.entity.profile.title" })}
                        setParameter={setParam("profile")}
                        parameters={form.params.profile} />

                    <ObjectRepositoryParamItem
                        label={intl.formatMessage({ id: "integration-schedule-page.entity.role.title" })}
                        setParameter={setParam("role")}
                        parameters={form.params.role} />

                    <ObjectRepositoryParamItem
                        label={intl.formatMessage({ id: "integration-schedule-page.entity.employee.title" })}
                        setParameter={setParam("employee")}
                        parameters={form.params.employee} />
                </>
            )}

        </Box>
    )
}

const ScheduleNameInput = ({ name, setName, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            className="integration-schedule-name-input"
            multiline
            label={intl.formatMessage({ id: "integration-schedule-page-name.input-description" })}
            value={name}
            onChange={(event) => setName(event.target.value)}
            disabled={disabled}
            error={error?.value === name}
            errorMessage={error?.message}
        />
    );
};

const getDefaultStartDate = () => {
    const now = new Date();
    const hour = now.getHours();

    now.setHours(hour + 1);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
}

const parseValidationErrors = (error, setError) => {
    const validationErrors = {}

    if (error.errors) {
        error.errors.forEach(error => {
            if (error.type !== "validation") return

            validationErrors[error.field] = error
        })

        setError(validationErrors)
    }
}

const IntegrationSchedulePage = () => {
    const { state: locationState } = useLocation();
    const previousDialogType = locationState.previousDialogType;

    const [form, setForm] = useState(null)
    const [validationErrors, setValidationErrors] = useState(null)
    const [error, setError] = useState()

    const navigate = useNavigate();

    const { system, systemType, scheduleType, taskId } = useParams();

    const navigateBack = useCallback((dialogType) => {
        let searchString = `?system=${system}`;

        if (dialogType) {
            searchString += `&schedule_type=${dialogType}`;
            searchString += `&system_type=${systemType}`;
        }

        navigate({
            pathname: "/integration",
            search: searchString
        });
    }, [navigate, system]);

    const editFl = !!taskId

    const initForm = (scheduleItem) => {
        setForm({
            id: scheduleItem?.id || null,
            name: scheduleItem?.name || "",
            type: scheduleItem.type || null,
            params: {
                role: {
                    checked: !!scheduleItem?.params?.role?.checked,
                    delta: !!scheduleItem?.params?.role?.delta
                },
                profile: {
                    checked: !!scheduleItem?.params?.profile?.checked,
                    delta: !!scheduleItem?.params?.profile?.delta
                },
                employee: {
                    checked: !!scheduleItem?.params?.employee?.checked,
                    delta: !!scheduleItem?.params?.employee?.delta
                },
                permissionDetails: {
                    delta: !!scheduleItem?.params?.permissionDetails?.delta
                },
            },
            firstLaunchDate: scheduleItem.firstLaunchDate,
            period: scheduleItem.period,
            periodValue: scheduleItem.periodValue || 1,
            customPeriod: scheduleItem.customPeriod,
        });
    };

    useEffect(() => {
        if (editFl) {
            (async () => {
                const data = await integrationService.getScheduleItemDetaield(taskId, scheduleType)

                initForm(data)
            })()

            return
        }

        initForm({
            name: "",
            type: scheduleType,
            firstLaunchDate: getDefaultStartDate(),
            period: "single",
            periodValue: 1,
            customPeriod: "hours",
        });
    }, [taskId, editFl, scheduleType]);

    const setName = (name) => {
        setForm(form => {
            return { ...form, name: name }
        })
    }

    const setFirstStartDate = (date) => {
        setForm(form => {
            return { ...form, firstLaunchDate: date }
        })
    }

    const setPeriod = (value) => {
        setForm(form => {
            return { ...form, period: value, periodValue: 1 }
        })
    }

    const setCustomPeriod = (value) => {
        setForm(form => {
            return { ...form, customPeriod: value }
        })
    }

    const setPeriodValue = (value) => {
        setForm(form => {
            return { ...form, periodValue: value }
        })
    }

    const onBackClick = () => {
        navigateBack(previousDialogType);
    }

    const onSave = async () => {
        const itemForSave = {
            ...form,
            name: form.name?.trim(),
            system: system,
            systemType: systemType
        }

        if (itemForSave.name !== form.name) {
            setForm({
                ...form,
                name: itemForSave.name
            });
        }

        if (itemForSave.period === "custom") {
            itemForSave.period = itemForSave.customPeriod
        }

        try {
            if (editFl) {
                await integrationService.editScheduleItem(itemForSave)
            } else {
                await integrationService.createScheduleItem(itemForSave)
            }
        } catch (error) {
            setError(error);
            return parseValidationErrors(error, setValidationErrors);
        }

        navigateBack(previousDialogType ?? scheduleType);
    }

    if (!form) return <CircularProgress />;

    return (

        <PageContentBox className="integration-schedule-page-form">

            <HeaderTitle
                onBackClick={onBackClick}
                mode={editFl ? EDIT_MODE : ADD_MODE}
                system={system}
                onSave={onSave} />

            <Box className="integration-schedule-page-inputs">
                <ScheduleNameInput
                    name={form.name}
                    setName={setName}
                    error={validationErrors && validationErrors["task_name"]}
                />

                {["permission_details", "repository_objects"].includes(form.type) && (
                    <IntegrationPramatersInput
                        form={form}
                        setForm={setForm}
                        error={validationErrors && validationErrors["params"]}
                    />
                )}

                <FirstStartDateField
                    date={form.firstLaunchDate}
                    onChange={setFirstStartDate}
                    error={validationErrors && validationErrors["start_time"]}
                />

                <Box className="integration-schedule-period-wrapper">
                    <PeriodField
                        value={form.period}
                        setValue={setPeriod}
                    />

                    <CustomPeriodField
                        hidden={form.period !== "custom"}
                        periodValue={form.periodValue}
                        setPeriodValue={setPeriodValue}
                        customPeriod={form.customPeriod}
                        setCustomPeriod={setCustomPeriod}
                        error={validationErrors && validationErrors["period_value"]}
                    />
                </Box>
            </Box>

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { setError(null) }}
            />
        </PageContentBox>

    )
}

HeaderTitle.propTypes = {
    system: PropTypes.string,
    mode: PropTypes.string,
    onBackClick: PropTypes.func,
    onSave: PropTypes.func
};

CustomPeriodField.propTypes = {
    periodValue: PropTypes.number,
    customPeriod: PropTypes.string,
    setPeriodValue: PropTypes.func,
    setCustomPeriod: PropTypes.func,
    hidden: PropTypes.bool,
    error: PropTypes.object
};

PeriodField.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    disabled: PropTypes.bool
};

FirstStartDateField.propTypes = {
    date: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    error: PropTypes.object
};

ObjectPermissionParamItem.propTypes = {
    label: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.object),
    setParameter: PropTypes.func,
};

ObjectRepositoryParamItem.propTypes = {
    label: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.object),
    setParameter: PropTypes.func,
};

IntegrationPramatersInput.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    error: PropTypes.object
};

ScheduleNameInput.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object
};

export default IntegrationSchedulePage