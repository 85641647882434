import produce from "immer";

import {
    REPORT_MODELING_DELTA_STATUSES,
    addItemToRoleTree,
    modifyItemFieldInRoleTree,
    updateRoleTreeOrgLevels,
    removeItemFromRoleTree,
    substractPfcgTreeFromRole
} from "../../utils/reports-modeling-utils";

import {
    addRoleMenuFolder,
    addRoleMenuNode,
    openRoleMenuNode,
    removeRoleMenuNode,
    copyRoleMenu
} from "../../utils/role-menu";

import {
    addGroupRoleChild,
    removeGroupRoleChildren
} from "../../utils/group-role-utils";

const getSetModelingRoleTreeFieldChanges = (role, action) => {
    const { itemPath, field, value } = action.payload;

    const changes = produce(
        { tree: role.tree, delta: role.delta },
        ({ tree, delta }) => modifyItemFieldInRoleTree(
            role.orgLevels, tree, itemPath, field, value, delta
        )
    );

    if (field !== "open") {
        changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;
    }

    return changes;
};

const getAddModelingRoleTreeItemsChanges = (role, action) => {
    const { items, defaultValues, tcodes, isStandart } = action.payload;

    const changes = produce(
        { tree: role.tree, orgLevels: role.orgLevels, delta: role.delta, orgLevelsDelta: role.orgLevelsDelta, tcodeToValue: role.tcodeToValue },
        ({ tree, orgLevels, delta, orgLevelsDelta, tcodeToValue }) => {
            for (const item of items) {
                try {
                    addItemToRoleTree(role.profile, orgLevels, tree, item, null, 0, defaultValues, delta, orgLevelsDelta, {}, isStandart);
                } catch (error) {
                    console.log(error)
                }
            }

            if(tcodes) {
                tcodes.forEach(tcode => {
                    tcodeToValue[tcode] = defaultValues.filter(value => value.permissionGroup === tcode)
                })
            }
            

            //tcodeToValue[tcode] = defaultValues

        }
    );

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getRemoveModelingRoleTreeItemChanges = (role, action) => {
    const { path } = action.payload;

    const changes = produce(
        { tree: role.tree, delta: role.delta, orgLevels: role.orgLevels },
        ({ tree, delta, orgLevels }) => removeItemFromRoleTree(tree, path, delta, orgLevels)
    );

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getSetModelingRoleOrgLevelValuesChanges = (role, action) => {
    const { values } = action.payload;

    const changes = produce(
        { orgLevels: role.orgLevels, orgLevelsDelta: role.orgLevelsDelta, tree: role.tree },
        ({ orgLevels, orgLevelsDelta, tree }) => updateRoleTreeOrgLevels(orgLevels, values, orgLevelsDelta, tree)
    );

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getOpenRoleMenuNode = (role, action) => {
    const { itemPath } = action.payload;

    const changes = {
        menu: produce(role.menu, draftMenu => openRoleMenuNode(
            itemPath, draftMenu
        ))
    };

    return changes;
};

const getAddRoleMenuFolder = (role, action) => {
    const { activeNodePath, folderName } = action.payload;

    const changes = {
        menu: produce(role.menu, draftMenu => addRoleMenuFolder(
            activeNodePath, draftMenu, folderName
        ))
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getAddRoleMenuNode = (role, action) => {
    const { activeNodePath, tcodes, transactions} = action.payload;

    const changes = {
        menu: produce(role.menu, draftMenu => addRoleMenuNode(
            activeNodePath, draftMenu, tcodes, transactions
        ))
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getRemoveRoleMenuNode = (role, action) => {
    const { activeNodePath } = action.payload;

    const changes = {
        menu: produce(role.menu, draftMenu => removeRoleMenuNode(
            activeNodePath, draftMenu
        ))
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getSubstractPfcgTreeFromRole = (role, action) => {
    const { defaultValuesForDelete, orgLevelsForDelete } = action.payload

    const changes = produce(
        {
            tree: role.tree, 
            delta: role.delta, 
            orgLevels: role.orgLevels, 
            orgLevelsDelta: role.orgLevelsDelta
        }, 
        ({tree, delta, orgLevels, orgLevelsDelta}) => substractPfcgTreeFromRole(
            tree, defaultValuesForDelete, delta, orgLevels, orgLevelsDelta, orgLevelsForDelete
        )
    )

    const newRoots = []

    changes.tree.forEach(node => {
        if (node.children && node.children.length === 0) {
            return
        }

        newRoots.push(node)
    })

    changes.tree = newRoots

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
}

const getCopyRoleMenu = (role, action) => {
    const { menuToCopy } = action.payload;

    const changes = {
        menu: produce(role.menu, draftMenu => copyRoleMenu(
            menuToCopy, draftMenu
        ))
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getAddGroupRoleChild = (role, action) => {
    const { newChildRole } = action.payload;

    const changes = {
        children: produce(role.children, draftChildren => addGroupRoleChild(
            newChildRole, draftChildren
        ))
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

const getRemoveGroupRoleChild = (role, action) => {
    const { roleIdsToRemove } = action.payload;

    const changes = {
        children: removeGroupRoleChildren(roleIdsToRemove, role.children)
    };

    changes.status = role.status || REPORT_MODELING_DELTA_STATUSES.CHANGED;

    return changes;
};

export const getSelectedRoleReducers = (rolesSelector, rolesAdapter, setPreviousRole, shouldSetChangesStatus) => {
    const changeRole = (state, action, getChanges) => {
        const { id } = action.payload;
        const roles = rolesSelector(state);
        const role = roles.entities[id];

        if (!role || !(role.tree || role.children)) {
            return;
        }

        const changes = getChanges(role, action);

        if (changes.status) {
            setPreviousRole(state, role);
        }

        if (!shouldSetChangesStatus) {
            delete changes.status;
        }

        rolesAdapter.updateOne(roles, {
            id, changes
        });
    };

    return {
        setModelingRoleTreeField: (state, action) => changeRole(
            state, action, getSetModelingRoleTreeFieldChanges
        ),

        addModelingRoleTreeItems: (state, action) => changeRole(
            state, action, getAddModelingRoleTreeItemsChanges
        ),

        removeModelingRoleTreeItem: (state, action) => changeRole(
            state, action, getRemoveModelingRoleTreeItemChanges
        ),

        setModelingRoleOrgLevelValues: (state, action) => changeRole(
            state, action, getSetModelingRoleOrgLevelValuesChanges
        ),

        openRoleMenuNode: (state, action) => changeRole(
            state, action, getOpenRoleMenuNode
        ),

        addRoleMenuFolder: (state, action) => changeRole(
            state, action, getAddRoleMenuFolder
        ),

        addRoleMenuNode: (state, action) => changeRole(
            state, action, getAddRoleMenuNode
        ),

        removeRoleMenuNode: (state, action) => changeRole(
            state, action, getRemoveRoleMenuNode
        ),

        copyRoleMenu: (state, action) => changeRole(
            state, action, getCopyRoleMenu
        ),

        addGroupRoleChild: (state, action) => changeRole(
            state, action, getAddGroupRoleChild
        ),

        removeGroupRoleChild: (state, action) => changeRole(
            state, action, getRemoveGroupRoleChild
        ),

        substractPfcgTreeFromRole: (state, action) => changeRole(
            state, action, getSubstractPfcgTreeFromRole
        )
    }
};