import React, { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { SYSTEM_TYPES, getIntegrationIcon, getSystemSyncTitle } from "../../../utils/integration-utils.js";

import breakpoints from "../../common/styles/breakpoints.js";

import { DetailsListItemRow, DetailsTitleItemRow, DetailsHeaderItemRow } from "./integration-details-row.jsx"
import IntegrationSystemBoxSmall from "./integration-system-box-small.jsx";
import ActionsBox from "./actions-box.jsx";
import { PlanIntegrationButton, OpenSelectIcon } from "./integration-common.jsx";

import "./styles.less";
import Button from "../../common/buttons/button.jsx";

const IntegrationSystemBox = ({
    integrationItem,
    editField,
    showResetStatus,
    onResetStatus,
    onStartClick,
    onDownloadClick,
    onPlanClick,
    onPlanAllClick,
    onHistoryClick,
    onUploadMatrixClick,
    onOpen,
    open
}) => {
    const isIntegrationPending = useMemo(() => [
        integrationItem.common.status,
        integrationItem.manualIntegration.status
    ].includes("pending"), [
        integrationItem.common.status,
        integrationItem.manualIntegration.status
    ]);

    const runIntegrationDisabled = useMemo(() => {
        const entitiesSAPForCheck = [
            "permissionDetail",
            "role",
            "employee",
            "profile"
        ]

        const entities1CForCheck = [
            "authorityDetail",
            "role1C",
            "metadataObject",
            "employee1C"
        ]

        const entitiesForCheck = integrationItem.systemType === SYSTEM_TYPES.SAP ? entitiesSAPForCheck : entities1CForCheck

        return isIntegrationPending || (
            entitiesForCheck.every(entity => !integrationItem[entity].checked)
        );
    }, [isIntegrationPending, integrationItem]);

    const matches1024 = useMediaQuery(`(min-width:  ${breakpoints.minLaptopBreakpoint})`);

    const handleOpenClick = () => {
        onOpen()
    };

    const contaiterRef = useRef()

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                contaiterRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
            }, 500)
        }
    }, [open]);

    if (!matches1024) return (
        <IntegrationSystemBoxSmall
            ref={contaiterRef}
            integrationItem={integrationItem}
            open={open}
            editField={editField}
            isIntegrationPending={isIntegrationPending}
            runIntegrationDisabled={runIntegrationDisabled}
            showResetStatus={showResetStatus}
            onResetStatus={onResetStatus}
            onOpenClick={handleOpenClick}
            onPlanClick={onPlanClick}
            onHistoryClick={onHistoryClick}
            onStartClick={onStartClick}
            onPlanAllClick={onPlanAllClick}
        />
    )

    return (
        <Box
            className="integration-system-box"
            ref={contaiterRef}
        >
            <SystemBoxHeader
                integrationItem={integrationItem}
                isIntegrationPending={isIntegrationPending}
                open={open}
                showResetStatus={showResetStatus}
                onResetStatus={onResetStatus}
                onOpenClick={handleOpenClick}
                onPlanClick={onPlanClick}
                onHistoryClick={onHistoryClick}
                onUploadMatrixClick={onUploadMatrixClick}
            />

            {open && (
                <SystemBoxContent
                    integrationItem={integrationItem}
                    editField={editField}
                    isIntegrationPending={isIntegrationPending}
                    runIntegrationDisabled={runIntegrationDisabled}
                    onStartClick={onStartClick}
                    onDownloadClick={onDownloadClick}
                    onPlanClick={onPlanClick}
                    onPlanAllClick={onPlanAllClick}
                    onHistoryClick={onHistoryClick}
                />
            )}
        </Box>
    );
};

const SystemBoxHeader = ({
    integrationItem,
    isIntegrationPending,
    open,
    showResetStatus,
    onResetStatus,
    onOpenClick,
    onPlanClick,
    onHistoryClick,
    onUploadMatrixClick
}) => {
    const intl = useIntl();
    const resetTitle = intl.formatMessage({ id: "integration-details-row.btn-reset-status" });
    const uploadMatrixTitle = intl.formatMessage({ id: "integration-details-row.btn-upload-matrix" });

    return (
        <Box className="integration-system-box-header">
            <IconButton
                className="integration-system-box-open"
                onClick={onOpenClick}
            >
                <OpenSelectIcon open={open} />
            </IconButton>

            <Box className="integration-system-box-header-system">
                <Typography
                    className="integration-system-box-system-title"
                >
                    {integrationItem.id}
                </Typography>

                <Typography
                    className="integration-system-box-system-description"
                >
                    {integrationItem.description}
                </Typography>
            </Box>

            <SystemSyncCommonBox
                integrationItem={integrationItem}
                onHistoryClick={onHistoryClick}
            />

            <SystemSyncManualBox
                integrationItem={integrationItem}
                onHistoryClick={onHistoryClick}
            />

            <Box className="integration-system-box-header-actions">
                {showResetStatus && (
                    <SystemBoxHeaderAction
                        title={resetTitle}
                        onClick={onResetStatus}
                    />
                )}

                <SystemBoxHeaderAction
                    title={uploadMatrixTitle}
                    onClick={onUploadMatrixClick}
                    disabled={isIntegrationPending}
                />

                <PlanIntegrationButton onClick={() => onPlanClick()} />
            </Box>
        </Box>
    );
};

const SystemBoxHeaderAction = ({ title, onClick, disabled }) => (
    <Button
        variant="outlined"
        className="integration-system-box-header-action"
        onClick={onClick}
        disabled={disabled}
    >
        {title}
    </Button>
);

const SystemSyncBox = ({ title, Icon, onClick }) => {
    return (
        <Box
            className="integration-system-box-sync-box"
            onClick={onClick}
        >
            {Icon && (
                <Box className="integration-system-box-sync-icon-wrapper">
                    <Icon
                        className="integration-system-box-sync-icon"
                        size="20px"
                    />
                </Box>
            )}

            <Typography
                className="integration-system-box-sync-title"
            >
                {title}
            </Typography>
        </Box>
    );
};

const SystemSyncCommonBox = ({ integrationItem, onHistoryClick }) => {
    const { status, lastSyncDate } = integrationItem.common;

    const intl = useIntl();
    const title = getSystemSyncTitle(intl, status, lastSyncDate, "common");
    const Icon = getIntegrationIcon(status);

    return (
        <SystemSyncBox
            title={title}
            Icon={Icon}
            onClick={() => onHistoryClick("common")}
        />
    );
};

const SystemSyncManualBox = ({ integrationItem, onHistoryClick }) => {
    const { status, lastSyncDate } = integrationItem.manualIntegration;
    const intl = useIntl();

    if (!status) {
        return <Box />;
    }

    const title = getSystemSyncTitle(intl, status, lastSyncDate, "manual");
    const Icon = getIntegrationIcon(status);

    return (
        <SystemSyncBox
            title={title}
            Icon={Icon}
            onClick={() => onHistoryClick("manual_integration")}
        />
    );
};

const SystemBoxContent = ({
    integrationItem,
    editField,
    onStartClick,
    onDownloadClick,
    onPlanClick,
    onPlanAllClick,
    onHistoryClick,
    isIntegrationPending,
    runIntegrationDisabled
}) => {
    const checkAll = (value) => {
        if (isIntegrationPending) return;

        if (integrationItem.systemType === SYSTEM_TYPES.SAP) {
            editField("permissionDetail", "checked", value);
            editField("role", "checked", value);
            editField("employee", "checked", value);
            editField("profile", "checked", value);
        } else {
            editField("authorityDetail", "checked", value);
            editField("role1C", "checked", value);
            editField("metadataObject", "checked", value);
            editField("employee1C", "checked", value);
        }
    };

    const calcCheckedAllSAP = () => {
        let result = true
        if (integrationItem.permissionDetail.status !== "pending") { result = result && integrationItem.permissionDetail.checked }
        if (integrationItem.role.status !== "pending") { result = result && integrationItem.role.checked }
        if (integrationItem.employee.status !== "pending") { result = result && integrationItem.employee.checked }
        if (integrationItem.profile.status !== "pending") { result = result && integrationItem.profile.checked }

        if (integrationItem.permissionDetail.status === "pending" &&
            integrationItem.role.status === "pending" &&
            integrationItem.employee.status === "pending" &&
            integrationItem.profile.status === "pending") {
            result = false
        }

        return result;
    }

    const calcCheckedAll1C = () => {
        let result = true
        if (integrationItem.authorityDetail.status !== "pending") { result = result && integrationItem.authorityDetail.checked }
        if (integrationItem.role1C.status !== "pending") { result = result && integrationItem.role1C.checked }
        if (integrationItem.metadataObject.status !== "pending") { result = result && integrationItem.metadataObject.checked }
        if (integrationItem.employee1C.status !== "pending") { result = result && integrationItem.employee1C.checked }

        if (integrationItem.authorityDetail.status === "pending" &&
            integrationItem.role1C.status === "pending" &&
            integrationItem.metadataObject.status === "pending" &&
            integrationItem.employee1C.status === "pending") {
            result = false
        }

        return result;
    }

    const checkedAll = integrationItem.systemType == SYSTEM_TYPES.SAP ? calcCheckedAllSAP() : calcCheckedAll1C()

    switch (integrationItem.systemType) {
        case SYSTEM_TYPES.SAP: {
            return (
                <Box className="integration-system-box-content">
                    <DetailsTitleItemRow />

                    <DetailsListItemRow
                        key={"permissionDetail"}
                        entity={"permissionDetail"}
                        entityData={integrationItem["permissionDetail"]}
                        isHeader={true}
                        editField={editField}
                        onPlanClick={() => {
                            onPlanClick("permission_details")
                        }}
                        onHistoryClick={() => {
                            onHistoryClick("permission_details")
                        }}
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsHeaderItemRow
                        label="Объекты репозитория"
                        onPlanClick={() => {
                            onPlanClick("repository_objects")
                        }}
                    />

                    <DetailsListItemRow
                        key={"profile"}
                        entity={"profile"}
                        entityData={integrationItem["profile"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("profile")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsListItemRow
                        key={"role"}
                        entity={"role"}
                        entityData={integrationItem["role"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("role")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsListItemRow
                        key={"employee"}
                        entity={"employee"}
                        entityData={integrationItem["employee"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("employee")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <ActionsBox
                        checkedAll={checkedAll}
                        isIntegrationPending={isIntegrationPending}
                        runIntegrationDisabled={runIntegrationDisabled}
                        onCheckedAllChange={() => checkAll(!checkedAll)}
                        onDownloadClick={onDownloadClick}
                        onStartClick={onStartClick}
                        onPlanClick={onPlanAllClick}
                        isSAPSystem
                    />
                </Box>
            )
        }

        case SYSTEM_TYPES.ONE_C: {
            return (
                <Box className="integration-system-box-content">
                    <DetailsTitleItemRow />

                    <DetailsListItemRow
                        key={"authorityDetail"}
                        entity={"authorityDetail"}
                        entityData={integrationItem["authorityDetail"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("authority_detail")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsListItemRow
                        key={"metadataObject"}
                        entity={"metadataObject"}
                        entityData={integrationItem["metadataObject"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("metadata_object")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsListItemRow
                        key={"employee1C"}
                        entity={"employee1C"}
                        entityData={integrationItem["employee1C"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("employee_1c")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <DetailsListItemRow
                        key={"role1C"}
                        entity={"role1C"}
                        entityData={integrationItem["role1C"]}
                        editField={editField}
                        onHistoryClick={() => {
                            onHistoryClick("role_1c")
                        }}
                        noPlan
                        isIntegrationPending={isIntegrationPending}
                    />

                    <ActionsBox
                        checkedAll={checkedAll}
                        isIntegrationPending={isIntegrationPending}
                        runIntegrationDisabled={runIntegrationDisabled}
                        onCheckedAllChange={() => checkAll(!checkedAll)}
                        onDownloadClick={onDownloadClick}
                        onStartClick={onStartClick}
                        onPlanClick={onPlanAllClick}
                    />
                </Box>
            );
        }
    }
};

IntegrationSystemBox.propTypes = {
    integrationItem: PropTypes.object,
    editField: PropTypes.func,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onStartClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onPlanAllClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onUploadMatrixClick: PropTypes.func
};

SystemBoxHeader.propTypes = {
    integrationItem: PropTypes.object,
    isIntegrationPending: PropTypes.bool,
    open: PropTypes.bool,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onOpenClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onUploadMatrixClick: PropTypes.func
};

SystemBoxHeaderAction.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

SystemSyncBox.propTypes = {
    title: PropTypes.node,
    Icon: PropTypes.elementType,
    onClick: PropTypes.func
};

SystemSyncCommonBox.propTypes = {
    integrationItem: PropTypes.object,
    onHistoryClick: PropTypes.func,
};

SystemSyncManualBox.propTypes = {
    integrationItem: PropTypes.object,
    onHistoryClick: PropTypes.func,
};

SystemBoxContent.propTypes = {
    integrationItem: PropTypes.object,
    editField: PropTypes.func,
    isIntegrationPending: PropTypes.bool,
    runIntegrationDisabled: PropTypes.bool,
    onStartClick: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onPlanAllClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
};

export default IntegrationSystemBox;
