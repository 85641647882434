import React from "react";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { TableRowCellText } from "../../common/table";
import breakpoints from "../../common/styles/breakpoints";
import { TrashIcon, ArrowDownIcon } from "../../common/icons";

import PfcgViewNodeButton from "./pfcg-view-node-button.jsx";
import PfcgViewLeafIconBox from "./pfcg-view-leaf-icon-box.jsx";

import "./styles.less";
import { useMediaQuery } from "@mui/material";
import TrafficLight from "../../common/traffic-light";

const PfcgViewNodeCellContent = ({
    open,
    title,
    level,
    leaf,
    trafficLightStatus,
    onClick,
    onRemove,
    onExpandItemTree
}) => {
    const matchesLaptop = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    const marginStep = matchesLaptop ? 24 : 16;

    const levelInner = leaf && level > 0
        ? level - 1
        : level;

    const firstItemSx = {
        marginLeft: `${levelInner * marginStep}px`
    };

    const trafficLight = <TrafficLight status={trafficLightStatus} />;

    return (
        <>
            {leaf ? (
                <PfcgViewLeafIconBox
                    sx={firstItemSx}
                    endContent={trafficLight}
                />
            ) : (
                <PfcgViewNodeButton
                    sx={firstItemSx}
                    open={open}
                    onClick={onClick}
                    endContent={trafficLight}
                />
            )}
            
            {!leaf && (
                <IconButton
                    className="pfcg-expand-node-button"
                    onClick={onExpandItemTree}
                >
                    <ArrowDownIcon />
                </IconButton>
            )}

            <TableRowCellText>
                {title}
            </TableRowCellText>

            {!leaf && (
                <IconButton
                    className="pfcg-delete-node-button"
                    onClick={onRemove}
                >
                    <TrashIcon />
                </IconButton>
            )}
        </>
    );
};

PfcgViewNodeCellContent.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    level: PropTypes.number,
    leaf: PropTypes.bool,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
    onExpandItemTree: PropTypes.func,
    trafficLightStatus: PropTypes.string
};

export default PfcgViewNodeCellContent;