import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
    selectModelingSelectedUser,
    setModelingSelectedUserByName,
    selectModelingUsers }
from "../../../../reducers/reports-modeling/userLevelSlice";

import { ReportModelingHeaderSearchHelpInput } from "../../report-modeling-header";

import UserLevelModelingUsersDialog from "../user-level-modeling-users-dialog";

const UserLevelModelingRoleSearchHelp = () => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    const label = intl.formatMessage({ id: "user-level-modeling-user-search-help.label" });

    const dispatch = useDispatch();

    const selectedUser = useSelector(selectModelingSelectedUser)

    const handleChange = (event) => {
        dispatch(setModelingSelectedUserByName(
            event.target.value
        ));
    };

    const users = useSelector(selectModelingUsers)

    useEffect(()=>{
        if(users.length && !selectedUser    )
        dispatch(setModelingSelectedUserByName(
            users[0].employee
        ));
    },[users])

    return (
        <>
            <ReportModelingHeaderSearchHelpInput
                label={label}
                value={selectedUser ? selectedUser.employee : ""}
                onChange={handleChange}
                onOpenSearchHelp={() => setOpen(true)}
            />

            {open && (
                <UserLevelModelingUsersDialog
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    );
};

export default UserLevelModelingRoleSearchHelp;