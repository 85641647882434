import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
    addModelingRoleTreeItems,
    addRoleMenuFolder,
    addRoleMenuNodeWithDefaultValues,
    copyRoleMenuWithDefaultValues,
    openRoleMenuNode,
    removeMenuItemFromTree,
    removeModelingRoleTreeItem,
    setModelingRoleOrgLevelValues,
    setModelingRoleTreeField,
    expandItemTree
} from "../../../reducers/reports-modeling/userLevelSlice";
import RoleModelingBox from "../role-modeling-box";

const UserLevelRoleModelingBox = ({ selectedRole }) => {
    const dispatch = useDispatch();

    const handleAddTreeItems = (items) => dispatch(
        addModelingRoleTreeItems({
            id: selectedRole.id,
            items
        })
    );

    const handleSetOrgLevelValues = (values) => dispatch(
        setModelingRoleOrgLevelValues({
            id: selectedRole.id,
            values
        })
    );

    const handleTreeItemFieldChange = (itemPath, field, value) => dispatch(
        setModelingRoleTreeField({
            id: selectedRole.id,
            itemPath,
            field,
            value
        })
    );

    const handleOpenRoleMenuNode = (itemPath) => dispatch(
        openRoleMenuNode({
            id: selectedRole.id,
            itemPath
        })
    );

    const handleAddRoleMenuFolder = (activeNodePath, folderName) => dispatch(
        addRoleMenuFolder({
            id: selectedRole.id,
            activeNodePath,
            folderName
        })
    );

    const handleAddRoleMenuNode = (activeNodePath, tcodes) => dispatch(
        addRoleMenuNodeWithDefaultValues({
            id: selectedRole.id,
            activeNodePath,
            tcodes
        })
    );

    const handleRemoveRoleMenuNode = (activeNodePath) => {

        // removeRoleMenuNode({
        //     id: selectedRole.id,
        //     activeNodePath
        // })

        dispatch(removeMenuItemFromTree({
            id: selectedRole.id,
            menuItemPath: activeNodePath
        }))
        // removeRoleMenuNode({
        //     id: selectedRole.id,
        //     activeNodePath
        // })
    };

    const handleCopyRoleMenu = (checkedMenuItems) => dispatch(
        copyRoleMenuWithDefaultValues({
            id: selectedRole.id,
            menuToCopy: checkedMenuItems
        })
    );

    const handleRemoveTreeItem = (path) => dispatch(
        removeModelingRoleTreeItem({
            id: selectedRole.id,
            path
        })
    );

    const handleExpandItemTree = (path) => dispatch(
        expandItemTree({
            path:path
        })
    );
    

    return (
        <RoleModelingBox
            role={selectedRole}
            onAddTreeItems={handleAddTreeItems}
            onSetOrgLevelValues={handleSetOrgLevelValues}
            onTreeItemFieldChange={handleTreeItemFieldChange}
            onOpenRoleMenuNode={handleOpenRoleMenuNode}
            onAddRoleMenuFolder={handleAddRoleMenuFolder}
            onAddRoleMenuNode={handleAddRoleMenuNode}
            onRemoveRoleMenuNode={handleRemoveRoleMenuNode}
            onCopyRoleMenu={handleCopyRoleMenu}
            onRemoveTreeItem={handleRemoveTreeItem}
            onExpandItemTree={handleExpandItemTree}

        />
    );
};

UserLevelRoleModelingBox.propTypes = {
    selectedRole: PropTypes.object
};

export default UserLevelRoleModelingBox;