import React, { useState, useMemo, useCallback } from "react";
import { Checkbox } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { getMassDisabled } from "../../../../utils/formViews";
import { getTransactionsSearchHelp, getObjectTypesSearchHelp, getObjectNamesSearchHelp } from "../../../../utils/searchHelps";

import { createRowCellContentInner } from "../../../common/table";
import { TrashIcon } from "../../../common/icons";
import { SearchHelpInput } from "../../../common/form-controls";
import SearchHelpDialog from "../../../common/search-helps";
import { EntityFormViewTable } from "../../../common/entity-form";
import { SYSTEM_TYPES } from "../../../../utils/integration-utils";

const getErrorFromValidationErrors = (row, columnId) => {
    return row && row[`error_${columnId}`];
};

const OperationsView = ({
    systemId,
    systemType,
    isGroup,
    operations,
    addOperation,
    deleteOperation,
    deleteOperationMass,
    editOperation,
    disabled,
    filterData,
    setFilterData,
    searchString,
    setSearchString,
    getDialogFilters,
    errorIndex,
    setErrorScroll
}) => {
    const intl = useIntl();

    const [selectedIds, setSelectedIds] = useState([]);
    const [rowAction, setRowAction] = useState(null);

    const onChangeCell = useCallback((row, column) => (e) => {
        const value = e.target.value;
        editOperation(row, column.id, value, systemType);
    }, [editOperation])

    const onOpenSearchHelp = useCallback((row, column) => () => {
        setRowAction({
            type: "search-help",
            row: row,
            field: column.id
        })
    }, [])

    const createRowCellContent = useCallback((row, column, selected, onSelect) => {
        const error = getErrorFromValidationErrors(row, column.id)

        switch (column.type) {
            case "search-help": {
                let hasError = error ? (row[column.id] === error.value && error["type"] === "validation") : false

                if (error && error.code === "duplicate"){
                    hasError = true
                }

                return (
                    <SearchHelpInput
                        disabled={disabled}
                        error={hasError}
                        errorMessage={error && error.message}
                        warning={error ? (row[column.id] === error.value && error["type"] === "integration_warning") : false}
                        warningMessage={error && error.message}
                        showFormHelpers={false}
                        showInnerHelpers
                        value={row[column.id]}
                        fullWidth
                        onOpenSearchHelp={onOpenSearchHelp(row, column)}
                        onChange={onChangeCell(row, column)} />
                )
            }

            case "boolean":
                return (
                    <Checkbox
                        disabled={disabled}
                        checked={row[column.id]}
                        sx={{
                            "&.Mui-checked": {
                                color: "gray"
                            },
                        }}
                        onClick={() => {
                            editOperation(row, column.id, !row[column.id], systemType);
                        }}
                    />
                );

            default:
                return createRowCellContentInner(row, column, selected, onSelect)
        }
    }, [disabled, editOperation, onChangeCell, onOpenSearchHelp]);

    const columnTexts = useMemo(() => ({
        operation: intl.formatMessage({ id: "function-form-page.subtable-column.operation" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
    }), [intl]);

    const columnTexts1C = useMemo(() => ({
        objectType: intl.formatMessage({ id: "function-form-page.subtable-column.objectType" }),
        objectName: intl.formatMessage({ id: "function-form-page.subtable-column.objectName" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
    }), [intl]);

    const columnsSAP = useMemo(() => [
        {
            id: "select",
            type: "select",
        },
        {
            id: "operation",
            title: columnTexts.operation,
            type: "search-help",
            filterable: true
        },
        {
            id: "description",
            title: columnTexts.description,
            type: "text",
            width: "40%"
        },
        {
            id: "active",
            title: columnTexts.active,
            type: "boolean"
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "delete",
                    disabled,
                    IconComponent: TrashIcon,
                    onClick: (_, row) => deleteOperation(row.id)
                }
            ]
        }
    ], [columnTexts, deleteOperation, disabled]);

    const columns1C = useMemo(() => [
        {
            id: "select",
            type: "select",
        },
        {
            id: "objectType",
            title: columnTexts1C.objectType,
            type: "search-help",
            filterable: true
        },
        {
            id: "objectName",
            title: columnTexts1C.objectName,
            type: "search-help",
            filterable: true
        },
        {
            id: "description",
            title: columnTexts1C.description,
            type: "text",
            width: "40%"
        },
        {
            id: "active",
            title: columnTexts1C.active,
            type: "boolean"
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "delete",
                    disabled,
                    IconComponent: TrashIcon,
                    onClick: (_, row) => deleteOperation(row.id)
                }
            ]
        }
    ], [columnTexts1C, deleteOperation, disabled]);

    const columns = systemType === SYSTEM_TYPES.SAP ? columnsSAP : columns1C

    const toolbarActions = useMemo(() => {
        const massDisabled = getMassDisabled(disabled, selectedIds);

        return [
            {
                id: "mass-delete",
                title: <FormattedMessage id="common.btn-delete" />,
                callback: () => {
                    deleteOperationMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "search",
                type: "search",
                position: "end",
                value: searchString,
                callback: setSearchString
            },
            {
                id: "add",
                title: <FormattedMessage id="operation-view.btn-create-operation" />,
                callback: addOperation,
                position: "begin",
                disabled
            }
        ];
    }, [
        addOperation,
        disabled,
        searchString,
        setSearchString,
        selectedIds,
        deleteOperationMass
    ]);

    const getSearchHelpData = useCallback((params, signal) => {
        switch (rowAction?.field) {
            case "operation":
                return getTransactionsSearchHelp(params, signal, systemId, isGroup);
            case "objectType":
                return getObjectTypesSearchHelp(params, signal, systemId, isGroup);
            case "objectName":
                return getObjectNamesSearchHelp(params, signal, systemId, isGroup, rowAction?.row?.objectType);
        }
    }, [rowAction, systemId, isGroup, systemType]);

    const searchHelpColumns = {
        "operation": [
            { id: "key", title: columnTexts.operation },
            { id: "text", title: columnTexts.description }
        ],
        "objectType": [
            { id: "key", title: columnTexts1C.objectType }
        ],
        "objectName": [
            { id: "key", title: columnTexts1C.objectName },
            { id: "text", title: columnTexts1C.description }
        ],
    };

    const handleSelectRow = useCallback((row, value) => {
        if (value) {
            setSelectedIds([...selectedIds, row.id]);
            return;
        }

        setSelectedIds(selectedIds.filter(id => id !== row.id));
    }, [selectedIds]);

    const handleSelectAll = useCallback((value) => {
        if (!value) {
            setSelectedIds([]);
            return;
        }

        setSelectedIds(operations.map(operation => operation.id));
    }, [operations]);

    return (
        <>
            <EntityFormViewTable
                rows={operations}
                columns={columns}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                onSelectRow={handleSelectRow}
                onSelectAll={handleSelectAll}
                createRowCellContent={createRowCellContent}
                filterData={filterData}
                setFilterData={setFilterData}
                getFilters={getDialogFilters}
                errorIndex={errorIndex}
                setErrorScroll={setErrorScroll}
            />

            {rowAction?.type === "search-help" && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={rowAction && rowAction.type === "search-help"}
                    readRows={getSearchHelpData}
                    columns={searchHelpColumns[rowAction.field]}
                    selectOneRow={true}
                    onSubmit={(selectedRow) => {
                        editOperation(rowAction.row, rowAction.field, selectedRow.key, systemType)
                        setRowAction(null)
                    }}
                    onCancel={() => {
                        setRowAction(null)
                    }}
                />
            )}
        </>
    )
}

OperationsView.propTypes = {
    operations: PropTypes.arrayOf(PropTypes.object),
    addOperation: PropTypes.func,
    deleteOperation: PropTypes.func,
    deleteOperationMass: PropTypes.func,
    editOperation: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    systemId: PropTypes.string,
    systemType: PropTypes.string,
    tableRef: PropTypes.any,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    getDialogFilters: PropTypes.func,
    errorIndex: PropTypes.number,
    setErrorScroll: PropTypes.func,
    isGroup: PropTypes.bool
}

export default OperationsView;
