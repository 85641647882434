import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { integrationService } from "../../services/integration-service";
import { getCheckedByEntitySAP, getCheckedByEntity1C } from "../../utils/integration-utils";
import { BUSY_TYPES } from "../../utils/busy";

const initialState = {
    items: [],
    total: 0,
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    busy: false,
    busyType: null,
    error: null,
    uploadErrors: null,
    uploadCompleted: false
};

export const runIntegrationUploadSAP = createAsyncThunk(
    "admin/runIntegrationUploadSAP",
    async (args, thunkAPI) => {
        try {
            await integrationService.runIntegrationSAPDataUpload(args);
        } catch (error){
            return thunkAPI.rejectWithValue(error)
        }
    
        return args;
    }
);

export const runIntegrationUpload1C = createAsyncThunk(
    "admin/runIntegrationUpload1C",
    async (args, thunkAPI) => {
        try {
            await integrationService.runIntegration1CDataUpload(args);
        } catch (error){
            return thunkAPI.rejectWithValue(error)
        }
    
        return args;
    }
);

export const downloadIntegrationData = createAsyncThunk(
    "admin/downloadIntegrationData",
    async (systemId, { thunkAPI }) => {
        try {
            await integrationService.downloadIntegrationData(systemId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchIntegrationItems = createAsyncThunk(
    "admin/fetchIntegrationItems",
    async ({ params }) => {
        const response = await integrationService.getIntegrationItems(params);
        return response;
    }
);

export const uploadMatrix = createAsyncThunk(
    "admin/uploadMatrix",
    async ({system, formData}, thunkAPI) => {
        try {
            await integrationService.uploadIntegrationMatrix(system, formData);
        } catch (error){
            const errors = error["errors"];
            const errorMap = {}

            if (errors) {
                errors.forEach(error => {
                    errorMap[error["field"]] = {message: error["message"]}
                })

                return thunkAPI.rejectWithValue(errorMap)
            }
        }
    }
);

export const resetIntegrationStatus = createAsyncThunk(
    "admin/resetIntegrationStatus",
    async (systemId, thunkAPI) => {
        try {
            await integrationService.resetIntegrationStatus(systemId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

const integrationSlice = createSlice({
    name: "integration",
    initialState: initialState,
    reducers: {
        editField(state, action){
            const {systemId, systemType, entity, field, value} = action.payload

            const item = state.items.find(item => item.id === systemId)

            if (!item) return;

            item[entity][field] = value;

            if (field === "checked") {
                if (systemType === "SAP") {

                    const updatedCheckedSAP = getCheckedByEntitySAP(
                        {
                            profile: item.profile.checked,
                            role: item.role.checked,
                            employee: item.employee.checked,
                        },
                        entity
                    );
    
                    item.profile.checked = updatedCheckedSAP.profile;
                    item.role.checked = updatedCheckedSAP.role;
                    item.employee.checked = updatedCheckedSAP.employee;

                } else {

                    const updatedChecked1C = getCheckedByEntity1C(
                        {   
                            authorityDetail: item.authorityDetail.checked,
                            metadataObject: item.metadataObject.checked,
                            employee1C: item.employee1C.checked,
                            role1C: item.role1C.checked
                        },
                        entity
                    );
    
                    item.authorityDetail.checked = updatedChecked1C.authorityDetail;
                    item.metadataObject.checked = updatedChecked1C.metadataObject;
                    item.employee1C.checked = updatedChecked1C.employee1C;
                    item.role1C.checked = updatedChecked1C.role1C;

                }
            }            
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setBusy(state, action) {
            state.busy = action.payload
        },

        clearUploadError(state, action){
            const field = action.payload

            const newUploadError = {...state.uploadErrors}

            delete newUploadError[field]

            state.uploadErrors = newUploadError
        },

        setUploadErrors(state, action){
            state.uploadErrors = action.payload;
        },

        setUploadCompleted(state, action){
            state.uploadCompleted = action.payload
        }
    },
    extraReducers: {
        //run sap integration
        [runIntegrationUploadSAP.fulfilled]: (state, action) => {
            state.busy = false;
            const item = state.items.find(item => item.id === action.payload.systemId)
            if ( item ) {
                item.permissionDetail.checked = false
                item.role.checked = false
                item.employee.checked = false
                item.profile.checked = false;
            }
        },

        [runIntegrationUploadSAP.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [runIntegrationUploadSAP.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        //run 1c integration
        [runIntegrationUpload1C.fulfilled]: (state, action) => {
            state.busy = false;
            const item = state.items.find(item => item.id === action.payload.systemId)
            if ( item ) {
                item.authorityDetail.checked = false
                item.metadataObject.checked = false
                item.employee1C.checked = false
                item.role1C.checked = false;
            }
        },

        [runIntegrationUpload1C.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [runIntegrationUpload1C.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        // upload matrix
        [uploadMatrix.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.uploadErrors = null;
            state.uploadCompleted = false
        },

        [uploadMatrix.rejected]: (state, action) => {
            state.busy = false;
            state.uploadErrors = action.payload
            state.uploadCompleted = false
        },

        [uploadMatrix.fulfilled]: (state) => {
            state.busy = false;
            state.uploadErrors = null;
            state.uploadCompleted = true
        },

        [resetIntegrationStatus.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [resetIntegrationStatus.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [resetIntegrationStatus.fulfilled]: (state) => {
            state.busy = false;
        },
        
        //fetch integration items
        [fetchIntegrationItems.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchIntegrationItems.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchIntegrationItems.fulfilled]: (state, action) => {
            state.busy = false;
            state.items = action.payload.items;
            state.total = action.payload.total;
            state.error = null;
        },

        // downloadIntegrationData
        [downloadIntegrationData.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [downloadIntegrationData.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;
        },

        [downloadIntegrationData.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },
    },
});

export const {
    editField, setError, setBusy,
    setSearchString, setPageNumber, setLimitNumber,
    clearUploadError, setUploadErrors, setUploadCompleted
} = integrationSlice.actions;

export default integrationSlice.reducer;
