import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    setResultsOpen as setResultsOpenAction,
    setReportLevelWithColumns,
    setReportTypeWithColumns,
    setConflictLevelWithColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    fetchReportColumns,
    fetchReportResults,
    exportReport
} from "../../../reducers/reports-modeling/roleLevelSlice";

import {selectModelingRolesContainsGroupRoles} from "../../../reducers/reports-modeling/roleLevelSlice"

import useReportReduxTable from "../../common/hooks/useReportReduxTable";

import ReportResults from "../../sod-reports/report-results";
import ConflictLevelsPicker from "../conflict-levels-picker";
import useReduxValue from "../../common/hooks/useReduxValue";
import { REPORT_MODELING_CONFLICT_LEVELS } from "../../../utils/reports-modeling-utils";

const RoleLevelModelingResults = () => {
    const dispatch = useDispatch();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        reportLevel, setReportLevel,
        reportType, setReportType,
        open, setOpen
    } = useReportReduxTable(
        (state) => state.reportsModeling.roleLevel.rows, fetchReportResults,
        (state) => state.reportsModeling.roleLevel.columns, fetchReportColumns,
        (state) => state.reportsModeling.roleLevel.selectedIds, setSelectedIdsAction,
        (state) => state.reportsModeling.roleLevel.searchString, setSearchStringAction,
        (state) => state.reportsModeling.roleLevel.pageNumber, setPageNumberAction,
        (state) => state.reportsModeling.roleLevel.limitNumber, setLimitNumberAction,
        (state) => state.reportsModeling.roleLevel.sortData, setSortDataAction,
        (state) => state.reportsModeling.roleLevel.filterData, setFilterDataAction,
        (state) => state.reportsModeling.roleLevel.error, setErrorAction,
        (state) => state.reportsModeling.roleLevel.total,
        (state) => state.reportsModeling.roleLevel.busy,
        (state) => state.reportsModeling.roleLevel.reportLevel, setReportLevelWithColumns,
        (state) => state.reportsModeling.roleLevel.reportType, setReportTypeWithColumns,
        (state) => state.reportsModeling.roleLevel.resultsOpen, setResultsOpenAction
    );

    const [conflictLevel, setConflictLevel] = useReduxValue(
        (state) => state.reportsModeling.roleLevel.conflictLevel,
        setConflictLevelWithColumns
    );

    const handleExportClick = () => {
        dispatch(exportReport());
    };  

    const isComplex = useSelector(selectModelingRolesContainsGroupRoles)

    const conflictLevels = useMemo(() => {
         if(isComplex){ 
            return Object.values({ 
                ROLE: "role",
                USERNAME: "username"
         })
        }

        return Object.values(REPORT_MODELING_CONFLICT_LEVELS);
    }, [isComplex])

    return (
        <ReportResults
            open={open}
            setOpen={setOpen}
            reportLevel={reportLevel}
            setReportLevel={setReportLevel}
            reportType={reportType}
            setReportType={setReportType}
            rows={rows}
            columns={columns}
            total={total}
            busy={busy}
            selectedIds={selectedIds}
            searchString={searchString}
            setSearchString={setSearchString}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            onExportClick={handleExportClick}
            filterId="role-report"
            collapseChildren={
                <ConflictLevelsPicker
                    value={conflictLevel}
                    conflictLevels={conflictLevels}
                    onChange={setConflictLevel}
                />
            }
            conflictLevel={conflictLevel}
        />
    );
};

export default RoleLevelModelingResults;