import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonsSwitch } from "../../common/buttons";

import "./styles.less";

const ConflictLevelsPicker = ({ value, onChange, conflictLevels }) => {
    const intl = useIntl();

    const buttonsList = useMemo(() => {
        return conflictLevels.map(level => ({
            id: level,
            title: intl.formatMessage({ id: `conflict-levels-picker.${level}` })
        }));
    }, [conflictLevels])

    return (
        <ButtonsSwitch
            className="conflict-levels-picker"
            buttonsList={buttonsList}
            value={value}
            onChange={onChange}
        />
    );
};

ConflictLevelsPicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    conflictLevels: PropTypes.arrayOf(PropTypes.string)
};

export default ConflictLevelsPicker;