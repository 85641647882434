import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
    setResultsOpen as setResultsOpenAction,
    setReportLevelWithColumns,
    setReportTypeWithColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
    fetchReportColumns,
    fetchReportResults,
    exportReport,
    setConflictLevelWithColumns
} from "../../../reducers/reports-modeling/userLevelSlice";

import useReportReduxTable from "../../common/hooks/useReportReduxTable";

import ReportResults from "../../sod-reports/report-results";
import ConflictLevelsPicker from "../conflict-levels-picker";
import { REPORT_MODELING_CONFLICT_LEVELS_USER } from "../../../utils/reports-modeling-utils";
import useReduxValue from "../../common/hooks/useReduxValue";


const UserLevelModelingResults = () => {
    const dispatch = useDispatch();

    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        reportLevel, setReportLevel,
        reportType, setReportType,
        open, setOpen
    } = useReportReduxTable(
        (state) => state.reportsModeling.userLevel.rows, fetchReportResults,
        (state) => state.reportsModeling.userLevel.columns, fetchReportColumns,
        (state) => state.reportsModeling.userLevel.selectedIds, setSelectedIdsAction,
        (state) => state.reportsModeling.userLevel.searchString, setSearchStringAction,
        (state) => state.reportsModeling.userLevel.pageNumber, setPageNumberAction,
        (state) => state.reportsModeling.userLevel.limitNumber, setLimitNumberAction,
        (state) => state.reportsModeling.userLevel.sortData, setSortDataAction,
        (state) => state.reportsModeling.userLevel.filterData, setFilterDataAction,
        (state) => state.reportsModeling.userLevel.error, setErrorAction,
        (state) => state.reportsModeling.userLevel.total,
        (state) => state.reportsModeling.userLevel.busy,
        (state) => state.reportsModeling.userLevel.reportLevel, setReportLevelWithColumns,
        (state) => state.reportsModeling.userLevel.reportType, setReportTypeWithColumns,
        (state) => state.reportsModeling.userLevel.resultsOpen, setResultsOpenAction
    );

    const [conflictLevel, setConflictLevel] = useReduxValue(
        (state) => state.reportsModeling.userLevel.conflictLevel,
        setConflictLevelWithColumns
    );

    const handleExportClick = () => {
        dispatch(exportReport());
    };

    const conflictLevels = useMemo(() => {
        return Object.values(REPORT_MODELING_CONFLICT_LEVELS_USER);
    }, [])

    return (
        <ReportResults
            open={open}
            setOpen={setOpen}
            reportLevel={reportLevel}
            setReportLevel={setReportLevel}
            reportType={reportType}
            setReportType={setReportType}
            rows={rows}
            columns={columns}
            total={total}
            busy={busy}
            selectedIds={selectedIds}
            searchString={searchString}
            setSearchString={setSearchString}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            onExportClick={handleExportClick}
            filterId="user-report"
            collapseChildren={
                <ConflictLevelsPicker
                    value={conflictLevel}
                    conflictLevels={conflictLevels}
                    onChange={setConflictLevel}
                />
            }
            conflictLevel={conflictLevel}
        />
    );
};

export default UserLevelModelingResults;
