import { useCallback } from "react";

import { createFiltersFromFilterData } from "../../../../utils/formViews";

import { Filter, ComplexFilter } from "../../../common/hooks/useLocalTable";
import useEntityFormViewTable from "../../../common/hooks/useEntityFormViewTable";

const getFilters = (searchString, filterData) => {
    const filters = [];

    if (searchString) {
        filters.push(new ComplexFilter(
            [
                new Filter("description", Filter.CO, searchString),
                new Filter("operation", Filter.CO, searchString)
            ],
            ComplexFilter.OR
        ));
    }

    if (filterData) {
        const createdFilters = createFiltersFromFilterData(filterData);
        filters.push(...createdFilters);
    }

    return filters;
};

const useOperationsTable = (operations) => {
    const getDialogFilters = useCallback(async ({ field, search="" }) => {
        const lowerSearch = search.toLowerCase();
        let keys;

        switch (field) {
            case "objectType": {
                keys = operations
                    .filter(({ objectType }) => (
                        objectType?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ objectType }) => objectType);
                break;
            }

            case "objectName": {
                keys = operations
                    .filter(({ objectName }) => (
                        objectName?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ objectName }) => objectName);
                break;
            }

            case "operation": {
                keys = operations
                    .filter(({ operation }) => (
                        operation?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ operation }) => operation);
                break;
            }
        }

        const uniqueKeys = [...new Set(keys)];
        const rows = uniqueKeys.map((key) => ({ key, text: key }));
        return { rows, total: rows?.length };
    }, [operations]);

    const viewTable = useEntityFormViewTable(operations, getFilters);

    return {
        ...viewTable,
        getDialogFilters
    };
};

export default useOperationsTable;
