import { integrationService } from "../services/integration-service";
import { reportsModelingService } from "../services/reports-modeling-service";
import { setClassCheckedIndeterminate } from "./reports-modeling-utils";
import { TreeWalker, createTreeNode, findItemInTree, modifyItemAndRecalcParentsFields, modifyItemInTreeRecursive } from "./tree-table";

export function openRoleMenuNode(itemPath, menuTree) {
    const item = findItemInTree(itemPath, menuTree);

    if (item) {
        item.open = !item.open;
    }
}

export function addRoleMenuFolder(activeNodePath, menuTree, folderName) {
    const item = findItemInTree(activeNodePath, menuTree);

    const newFolderNode = {
        reportName: folderName,
        reportType: "",
        isFolder: true,
        nodeText: folderName,
        description: "",
        children: []
    };

    if (item?.children) {
        item.children.push(
            createTreeNode(newFolderNode, item.level + 1, item.path)
        );
    } else {
        const activeParentNodePath = [...activeNodePath]
        activeParentNodePath.pop()

        const parentItem = findItemInTree(activeParentNodePath, menuTree);

        parentItem && parentItem.children.push(
            createTreeNode(newFolderNode, item.level, parentItem.path)
        );
    }
}

export function addRoleMenuNode(activeNodePath, menuTree, tcodes, transactions) {
    const item = findItemInTree(activeNodePath, menuTree);

    const newNodes = transactions.map(transaction => ({
        reportName: transaction.key,
        reportType: "TR",
        isFolder: false,
        nodeText: transaction.text,
        description: ""
    }))



    if (item?.children) {
        item.children = item.children.concat(newNodes.map(tcodeNode => createTreeNode(tcodeNode, item.level + 1, item.path)));
    } else {
        const activeParentNodePath = [...activeNodePath]
        activeParentNodePath.pop()

        const parentItem = findItemInTree(activeParentNodePath, menuTree);

        if (parentItem && parentItem.children){
            parentItem.children = parentItem.children.concat(newNodes.map(tcodeNode => createTreeNode(tcodeNode, item.level, parentItem.path)))
        }
    }
}

export function removeRoleMenuNode(activeNodePath, menuTree) {
    const parentPath = activeNodePath.slice(0, -1);
    const itemId = activeNodePath[activeNodePath.length - 1];
    const parent = findItemInTree(parentPath, menuTree);
    parent.children = parent.children.filter(child => child.rowId !== itemId);
}

function copyRoleMenuItem(roleMenuItem, rootId) {
    const menuNodeToCopy = {
        ...roleMenuItem,
        path: [...roleMenuItem.path]
    };

    delete menuNodeToCopy.checked;
    delete menuNodeToCopy.indeterminate;
    menuNodeToCopy.path[0] = rootId;

    if (menuNodeToCopy.children?.length) {
        menuNodeToCopy.children = menuNodeToCopy.children.map(
            child => copyRoleMenuItem(child, rootId)
        );
    }

    return menuNodeToCopy;
}

export function copyRoleMenu(menuToCopy, currentMenu) {
    if (!menuToCopy?.length) {
        return;
    }

    const rootId = currentMenu[0].rowId;

    for (const menuItem of menuToCopy) {
        if (!menuItem.children?.length) {
            continue;
        }

        const copiedMenuChildren = menuItem.children?.map(
            child => copyRoleMenuItem(child, rootId)
        );

        currentMenu[0].children.push(...copiedMenuChildren);
    }
}

export function getTcodesMapCountFromMenu(menu){

    const tcodesMap = {}

    const menuWalker = new TreeWalker(menu)

    menuWalker.walk(
        node => {
            if (!node.isFolder){
                if (!(node.reportName in tcodesMap)){
                    tcodesMap[node.reportName] = 0
                }

                tcodesMap[node.reportName] += 1
            }
        },
        () => {
            return {
                keyField: "",
                keyValue: "",
                childField: "children"
            }
        },
        () => {

        }
    )

    return tcodesMap
}

export function getDeletedTcodesFromMenu(tcodesMapItem, tcodesMapMenu){

    const deletedTcodes = new Set()
    const remainedTcodes = new Set()

    Object.entries(tcodesMapItem).forEach(([tcode, count]) => {

        if (!(tcode in tcodesMapMenu)){
            return
        }

        const tcodeInMenuCount = tcodesMapMenu[tcode]

        if (tcodeInMenuCount - count === 0){
            deletedTcodes.add(tcode)
        }
    })

    Object.keys(tcodesMapMenu).forEach(tcode => {
        if (deletedTcodes.has(tcode)){
            return
        }

        remainedTcodes.add(tcode)
    })

    return {deletedTcodes, remainedTcodes}
}

export function getTransactionFromRoleMenu(menuChildren) {
    const transactions = [];

    for (const child of menuChildren) {
        if (child.reportType === "TR" && child.reportName) {
            transactions.push(child.reportName);
        }

        if (!child.children?.length) {
            continue;
        }

        const childrenTransactions = getTransactionFromRoleMenu(child.children);
        transactions.push(...childrenTransactions);
    }

    return transactions;
}

export function modifyCopyMenuTree(tree, itemPath, field, value) {
    if (field === "checked") {
        modifyItemAndRecalcParentsFields(
            tree,
            itemPath,
            (item) => {
                item.indeterminate = false;
                modifyItemInTreeRecursive(item, field, value);
            },
            setClassCheckedIndeterminate
        );
        return;
    }

    const item = findItemInTree(itemPath, tree, "rowId");

    if (item) {
        item[field] = value;
    }
}

export async function getDefaultPermissionsValuesForTransactions(systemId, transactionIds) {
    const filters = [
        { field: "systemId", sign: "EQ", value: systemId }
    ];

    if (transactionIds.length === 1) {
        filters.push({ field: "transaction", sign: "EQ", value: transactionIds[0] });
    } else {
        filters.push({ field: "transaction", sign: "IN", value: transactionIds });
    }

    const { rows } = await integrationService.getFunctionDefaultPermissions({
        filters
    });

    return rows;
}

export async function getTransactionsByIds(systemId, transactionIds){
    const filters = [
        { field: "systemId", sign: "EQ", value: systemId }
    ];

    filters.push({field:"transaction", sign:"IN", value:transactionIds})

    const { rows } = await integrationService.getTransactions({
        filters
    });

    return rows;
}

export async function getNewPermissionsTree(systemId, permissionIds) {
    // const newPermissionIds = filterNewPermissionsInRoleTree(
    //     roleTree,
    //     permissionIds
    // );

    if (!permissionIds.size) {
        return [];
    }

    const permissionsTree = await reportsModelingService.getPermissionsTree(
        null, null, systemId, Array.from(permissionIds)
    );

    return permissionsTree.rows;
}

export async function getPfcgTreeForTcodes(systemId, tcodesList){
    const params = {
        tcodes: tcodesList.join(","),
        system: systemId
    }
    const { rows } = await integrationService.getPfcgTreeForTcode(params);

    return reportsModelingService.parsePermissionsTree(rows, new Set());
}