import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { createFuntion, editFunction, deleteFunction, clearCurrentFunction } from "../../../../reducers/functionSlice";
import { fetchFunctionDetailed, setInitialCurrentFunction, setSubmit } from "../../../../reducers/functionSlice";
import { setError, setValidationErrors } from "../../../../reducers/functionSlice";

import { prepareEntitiesForSave } from "../../../../utils/formViews";

import { DeleteDialog, ProgressDialog } from "../../../common/dialogs";
import { ErrorsDialog, SubmitDialog, UserLogDialog } from "../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, EntityFormPageContent, getCurrentMode } from "../../../common/entity-form";
import { CHANGE_STATUS } from "../../../common/const";
import useLogs from "../../../common/hooks/useLogs";

import FunctionFormPageHeader from "./function-form-page-header.jsx";
import FunctionFormPageFunction from "./function-form-page-function.jsx";

import "./style.less";
import { SYSTEM_TYPES } from "../../../../utils/integration-utils.js";

const checkIfRowHasMatchWithKey = (row, key, fieldMapping) => {
    const checkRowArr = []

    for (let field in key) {
        const rowField = fieldMapping[field];

        if (!rowField) {
            checkRowArr.push(false)
            return;
        }

        checkRowArr.push(row[rowField] == key[field])
    }

    return checkRowArr.every(item => item)
}

const pasteErrorsToRows = ({ form, setForm, validationErrors, setValidationErrors, dispatch }) => {
    const formCopy = JSON.parse(JSON.stringify(form))

    let tab = null, errorIndex = null

    validationErrors.forEach(error => {
        if (!error.additional_info?.key) return;

        if (error.field === "actions") {
            const fieldMapping = { 
                action: "operation", 
                object_type: "objectType",
                object_name: "objectName"
            };

            formCopy.operations.forEach((action, index) => {
                const rowMatched = checkIfRowHasMatchWithKey(
                    action, error.additional_info.key, fieldMapping
                )

                if (rowMatched) {
                    action[`error_${fieldMapping[error.additional_info.field]}`] = error

                    if (error.code === "duplicate"){
                        action[`error_${fieldMapping[error.additional_info.field]}`] = error
                        action[`error_objectType`] = error
                        action[`error_objectName`] = error
                    }

                    if (tab === null) tab = "operations"
                    if (errorIndex === null) errorIndex = index
                }
            })
        }

        if (error.field === "permissions") {
            const fieldMapping = {
                permission: "permission",
                fieldname: "field",
                permission_group: "permissionGroup",
                value_from: "valueFrom",
                value_to: "valueTo",
                condition: "sign",
                object_type: "objectType",
                object_name: "objectName",
                field_1с: "field1C",
                value: "value"
            }

            formCopy.permissions.forEach((permission, index) => {
                const rowMatched = checkIfRowHasMatchWithKey(
                    permission, error.additional_info.key, fieldMapping
                )

                if (rowMatched) {
                    permission[`error_${fieldMapping[error.additional_info.field]}`] = error

                    if (error.code === "duplicate"){
                        permission[`error_${fieldMapping[error.additional_info.field]}`] = error
                        permission[`error_permission`] = error
                        permission[`error_permissionGroup`] = error
                        permission[`error_field`] = error
                        permission[`error_valueFrom`] = error
                        permission[`error_valueTo`] = error
                        
                        permission[`error_field1C`] = error
                        permission[`error_value`] = error
                        permission[`error_objectType`] = error
                        permission[`error_objectName`] = error
                    }

                    if (tab === null) tab = "permissions"
                    if (errorIndex === null) errorIndex = index
                }

            })
        }

    });

    setForm(formCopy)

    dispatch(setValidationErrors(null))

    return {errorTab: tab, errorIndex}
}

const trimOperation = (operation) => ({
    ...operation,
    operation: operation.operation?.trim(),
    objectType: operation.objectType?.trim(),
    objectName: operation.objectName?.trim(),
});

const trimPermission = (permission) => ({
    ...permission,
    permissionGroup: permission.permissionGroup?.trim(),
    permission: permission.permission?.trim(),
    field: permission.field?.trim(),
    valueFrom: permission.valueFrom?.trim(),
    valueTo: permission.valueTo?.trim(),

    objectType: permission.objectType?.trim(),
    objectName: permission.objectName?.trim(),
    field1C: permission.field1C?.trim(),
    value: permission.value?.trim()
});

const calcPermissionsSequence = (permissions) => {
    const groupSeqMap = {};
    const permissionsWithSequence = [];
    const changedPermissionsWithoutStatus = [];

    permissions.forEach(permission => {
        if (permission.changeStatus === CHANGE_STATUS.DELETE) {
            permissionsWithSequence.push({
                ...permission,
                sequence: null
            });
            return
        }

        const trimmedGroup = permission.permissionGroup?.trim();

        if (trimmedGroup in groupSeqMap){
            groupSeqMap[trimmedGroup] += 1;
        } else {
            groupSeqMap[trimmedGroup] = 1;
        }

        const newSequence = groupSeqMap[trimmedGroup];

        if (permission.sequence === newSequence) {
            permissionsWithSequence.push(permission);
            return;
        }

        const changedPermission = {
            ...permission,
            sequence: newSequence
        };

        if (!changedPermission.changeStatus) {
            changedPermissionsWithoutStatus.push(changedPermission);
        }

        permissionsWithSequence.push(changedPermission);
    });

    return {
        permissionsWithSequence,
        changedPermissionsWithoutStatus
    };
};

const FunctionFormPage = () => {
    const { functionId } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const busy = useSelector(state => state.functions.busy);
    const busyType = useSelector((state) => state.functions.busyType);
    const error = useSelector(state => state.functions.error);
    const submit = useSelector(state => state.functions.submit);
    const func = useSelector(state => state.functions.currentFunction);
    const functionEditable = useSelector(state => state.functions.currentFunctionEditable);
    const functionDeleted = useSelector(state => state.functions.currentFunctionDeleted);
    const validationErrors = useSelector(state => state.functions.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("function", functionId)

    const [formErrors, setFormErrors] = useState(null)

    const mode = getCurrentMode(!functionId, functionEditable);

    const [errorScroll, setErrorScroll] = useState()

    useEffect(() => {
        if (!validationErrors) return;

        const errorScrollData = pasteErrorsToRows({
            form, setForm, validationErrors, setValidationErrors, dispatch
        });


        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            if (error.field === "actions" && error.additional_info && error.additional_info.field === "system_id") {
                accumulator["system_id"] = error;

                return accumulator
            }

            if (error.field === "permissions" && error.additional_info && error.additional_info.field === "system_id") {
                accumulator["system_id"] = error;

                return accumulator
            }

            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

        setErrorScroll(errorScrollData)

    }, [validationErrors, form, dispatch])

    useEffect(() => {
        if (functionId) {
            dispatch(fetchFunctionDetailed(functionId));
        } else {
            dispatch(setInitialCurrentFunction());
        }

        return (() => {
            dispatch(clearCurrentFunction());
        });
    }, [functionId, dispatch]);

    useEffect(() => {
        if (func) {
            setForm({
                id: func?.id || "",
                description: func?.description || "",
                volume: func?.volume || null,
                process: func?.process || null,
                systemId: func?.systemId || "",
                isGroup: func?.isGroup || false,
                systemType: func?.systemType || SYSTEM_TYPES.SAP,
                operations: func?.operations ? JSON.parse(JSON.stringify(func?.operations)) : [],
                permissions: func?.permissions ? JSON.parse(JSON.stringify(func?.permissions)) : [],
                operations1CBuffer: [],
                permissions1CBuffer: [],
                operationsSAPBuffer: [],
                permissionsSAPBuffer: []
            });
        }
    }, [func]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || functionDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, functionDeleted, handleBackClick, mode]);

    const handleSaveFunction = (checkWarnings = true) => async () => {
        const formFunction = {
            id: form.id?.trim(),
            systemId: form.systemId,
            isGroup: form.isGroup,
            systemType: form.systemType,
            description: form.description,
            process: form.process
        };

        const {
            allEntities: allOperations,
            deltaEntities: deltaOperations
        } = prepareEntitiesForSave(form?.operations || [], trimOperation);

        const {
            permissionsWithSequence,
            changedPermissionsWithoutStatus
        } = calcPermissionsSequence(form?.permissions || []);

        const {
            allEntities: allPermissions,
            deltaEntities: deltaPermissions
        } = prepareEntitiesForSave(permissionsWithSequence, trimPermission);

        formFunction.actions = deltaOperations;
        formFunction.permissions = [...deltaPermissions, ...changedPermissionsWithoutStatus];

        setForm({
            ...form,
            id: formFunction.id,
            operations: allOperations,
            permissions: allPermissions,
            operations1CBuffer: [],
            permissions1CBuffer: [],
            operationsSAPBuffer: [],
            permissionsSAPBuffer: []
        });

        if (mode === EDIT_MODE) {
            dispatch(editFunction({ editedFunction: formFunction, warningCheck: checkWarnings }));
        } else {
            dispatch(createFuntion({ newFunction: formFunction, warningCheck: checkWarnings }));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteFunction(functionId));
    };

    const handleEditClick = () => {
        dispatch(fetchFunctionDetailed(functionId));
    };

    if (!func && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <EntityFormPageContent>
                    <FunctionFormPageHeader
                        mode={mode}
                        functionId={functionId}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveFunction(true)}
                        onEditClick={handleEditClick}
                        onShowLogsClick={getLogs}
                    />

                    <FunctionFormPageFunction
                        mode={mode}
                        form={form}
                        setForm={setForm}
                        validationErrors={formErrors}
                        setValidationErrors={setFormErrors}
                        errorScroll={errorScroll}
                        setErrorScroll={setErrorScroll}
                    />
                </EntityFormPageContent>
            )}

            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "function-form-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "function-form-page.delete-function.text" },
                    { name: functionId }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <SubmitDialog
                open={error && error.type === "warning" && error.code === "no_integr_objects"}
                title={error && error.title}
                message={error && error.detail}
                onCancelClick={() => { dispatch(setError(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    type: "close",
                    onClick: () => { dispatch(setError(null)) }
                }, {
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-apply" }),
                    type: "apply",
                    onClick: () => {
                        handleSaveFunction(false)();
                        dispatch(setError(null))
                    }
                }]}
                disableRestoreFocus
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
                disableRestoreFocus
            />

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
                disableRestoreFocus
            />
        </>
    );
};

export default FunctionFormPage;
