import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import LockManager from "../utils/lockManager";
import { functionsService } from "../services/functions-service";
import { BUSY_TYPES } from "../utils/busy";

const lockManager = new LockManager("function");

const initialState = {
    functions: [],
    columns: [],
    functionsTotal: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentFunction: null,
    currentFunctionEditable: false,
    currentFunctionDeleted: false,
    validationErrors: null,
};

export const fetchFunctions = createAsyncThunk(
    "function/fetchFunctions",
    async ({ params }) => {
        const response = await functionsService.getFunctions(params);
        return response;
    }
);

export const fetchFunctionColumns = createAsyncThunk(
    "function/fetchFunctionColumns",
    async () => {
        const response = await functionsService.getFunctionColumns();
        return response;
    }
);

export const fetchFunctionDetailed = createAsyncThunk(
    "function/fetchFunctionDetailed",
    async (functionId, {rejectWithValue}) => {
        let lockResponse;
        let functionResponse;

        try{
            functionResponse = await functionsService.getFunctionDetailed(functionId);
            lockResponse = await lockManager.lockObject(functionId);
        } catch (error){
            return rejectWithValue(error)
        }

        return {
            function: functionResponse,
            lock: lockResponse
        };
    }
);

export const createFuntion = createAsyncThunk(
    "function/createFunction",
    async ({newFunction, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await functionsService.createFunction(newFunction, warningCheck);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editFunction = createAsyncThunk(
    "function/editFunction",
    async ({editedFunction, warningCheck=true}, { rejectWithValue }) => {
        let response

        try {
            response = await functionsService.editFunction(editedFunction, warningCheck);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteFunction = createAsyncThunk(
    "function/deleteFunctions",
    async (functionId, { rejectWithValue }) => {
        try {
            await functionsService.deleteFunction(functionId);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return functionId;
    }
);

export const clearCurrentFunction = createAsyncThunk(
    "function/clearCurrentFunction",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentFunction = state.functions.currentFunction;
        const currentFunctionEditable = state.functions.currentFunctionEditable;
        const currentFunctionDeleted = state.functions.currentFunctionDeleted;

        if (!currentFunction) return thunkAPI.rejectWithValue(["Current function is not initialized"]);

        if (currentFunctionDeleted || !currentFunctionEditable || !currentFunction.id) return;

        const lockResponse = await lockManager.unlockObject(currentFunction.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

const functionSlice = createSlice({
    name: "function",
    initialState: initialState,
    reducers: {
        setSubmit(state, action) {
            state.submit = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setInitialCurrentFunction(state) {
            state.currentFunction = {
                id: "",
                description: "",
                businessProcess: null,
                volume: null,
                process: null,
                operations: [],
                permissions: [],
                operations1CBuffer: [],
                permissions1CBuffer: [],
                operationsSAPBuffer: [],
                permissionsSAPBuffer: [],
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentFunctionEditable = true;
            state.currentFunctionDeleted = false;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all functions
        [fetchFunctions.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchFunctions.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchFunctions.fulfilled]: (state, action) => {
            state.busy = false;
            state.functions = action.payload.functions;
            state.functionsTotal = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // clear current function
        [clearCurrentFunction.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentFunction.fulfilled]: (state) => {
            state.busy = false;
            state.currentFunction = null;
            state.currentFunctionEditable = false;
            state.currentFunctionDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentFunction.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        // delete functions
        [deleteFunction.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteFunction.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteFunction.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.functions = state.functions.filter(func => func.id !== action.payload);

            if (state.currentFunction?.id === action.payload) {
                state.submit = true;
                state.currentFunctionDeleted = true;
            }
        },

        // create function
        [createFuntion.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createFuntion.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [createFuntion.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentFunction = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // edit function
        [editFunction.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editFunction.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [editFunction.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentFunction = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch function detailed
        [fetchFunctionDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentFunctionDeleted = false;
            state.submit = false;
        },

        [fetchFunctionDetailed.rejected]: (state, action) => {
            state.currentFunction = null;
            state.error = action.payload;
            state.busy = false;
            state.currentFunctionEditable = false;
        },

        [fetchFunctionDetailed.fulfilled]: (state, action) => {
            const { function: func, lock } = action.payload;

            state.currentFunction = func;
            state.busy = false;
            state.currentFunctionEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        [fetchFunctionColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchFunctionColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchFunctionColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
    }
})

export const {
    setSubmit, setInitialCurrentFunction, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = functionSlice.actions;

export default functionSlice.reducer;
