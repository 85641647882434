import { useCallback } from "react";
import { createFiltersFromFilterData } from "../../../../utils/formViews";

import { Filter } from "../../../common/hooks/useLocalTable";
import useEntityFormViewTable from "../../../common/hooks/useEntityFormViewTable";

const getFilters = (searchString, filterData) => {
    const filters = [];

    if (searchString) {
        filters.push(new Filter("permission", Filter.CO, searchString));
    }

    if (filterData) {
        const createdFilters = createFiltersFromFilterData(filterData);
        filters.push(...createdFilters);
    }

    return filters;
};

const usePermissionsTable = (permissions) => {
    const getDialogFilters = useCallback(async ({ field, search="" }) => {
        const lowerSearch = search.toLowerCase();
        let keys;

        switch (field) {
            case "permissionGroup": {
                const permissionGroupsSet = new Set(permissions
                    .filter(({ permissionGroup }) => permissionGroup?.toLowerCase().includes(lowerSearch))
                    .map(({ permissionGroup }) => permissionGroup));
        
                keys = [...permissionGroupsSet];
                break;
            }

            case "permission": {
                keys = permissions
                    .filter(({ permission }) => (
                        permission?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ permission }) => permission);
                break;
            }

            case "field": {
                keys = permissions
                    .filter(({ field }) => (
                        field?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ field }) => field);
                break;
            }

            case "objectType": {
                keys = permissions
                    .filter(({ objectType }) => (
                        objectType?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ objectType }) => objectType);
                break;
            }

            case "objectName": {
                keys = permissions
                    .filter(({ objectName }) => (
                        objectName?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ objectName }) => objectName);
                break;
            }

            case "field1C": {
                keys = permissions
                    .filter(({ field1C }) => (
                        field1C?.toLowerCase().includes(lowerSearch)
                    ))
                    .map(({ field1C }) => field1C);
                break;
            }
        }

        const uniqueKeys = [...new Set(keys)];
        const rows = uniqueKeys.map((key) => ({ key, text: key }));
        return { rows, total: rows?.length };
    }, [permissions]);

    const viewTable = useEntityFormViewTable(permissions, getFilters);

    return {
        ...viewTable,
        getDialogFilters
    };
};

export default usePermissionsTable;
