import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { setModelingSelectedRoleByName, selectModelingSelectedRole, selectModelingRoles } from "../../../../reducers/reports-modeling/roleLevelSlice";

import { ReportModelingHeaderSearchHelpInput } from "../../report-modeling-header";

import RoleLevelModelingRolesDialog from "../role-level-modeling-roles-dialog";

const RoleLevelModelingRoleSearchHelp = () => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    const label = intl.formatMessage({ id: "role-level-modeling-role-search-help.label" });

    const dispatch = useDispatch();

    const selectedRole = useSelector(selectModelingSelectedRole);

    const handleChange = (event) => {
        dispatch(setModelingSelectedRoleByName(
            event.target.value
        ));
    };

    const roles = useSelector(selectModelingRoles)

    useEffect(()=>{
        if(roles.length && !selectedRole)
        dispatch(setModelingSelectedRoleByName(
            roles[0].role
        ));
    },[roles])

    return (
        <>
            <ReportModelingHeaderSearchHelpInput
                label={label}
                value={selectedRole ? selectedRole.role : ""}
                onChange={handleChange}
                onOpenSearchHelp={() => setOpen(true)}
            />

            <RoleLevelModelingRolesDialog
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

RoleLevelModelingRoleSearchHelp.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default RoleLevelModelingRoleSearchHelp;