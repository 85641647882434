import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Switch } from "@mui/material";
import PropTypes from "prop-types";

import { createRowCellContentInner, getCellClassNameInner } from "../../common/table";
import TreeTable from "../../common/tree-table";

import { PfcgViewEditButton, PfcgViewResetButton } from "./pfcg-view-action-buttons.jsx";
import PfcgViewNodeCellContent from "./pfcg-view-node-cell-content.jsx";
import { REPORT_MODELING_STATUSES } from "../../../utils/reports-modeling-utils";

const getCellClassName = (row, column, selected) => {
    let className = getCellClassNameInner(row, column, selected);

    className += " pfcg-view-table-cell";

    if (row.type) {
        className += ` pfcg-view-table-cell-row-${row.type}`;
    }

    if (column.type) {
        className += ` pfcg-view-table-cell-column-${column.type}`;
    }

    return className;
};


const getCellValue = (row, column, orgLevels, intl) => {
    switch (column.id) {
        case "status":
            return intl.formatMessage({ id: `pfcg-view.statuses.${row.status}` });

        case "value": {
            if (row.type !== "field") return;

            const values = row.orgLevel in orgLevels && !row.values
                ? orgLevels[row.orgLevel].values
                : row.values;

            const cellValue = values?.reduce((cellValue, valueItem, index) => {
                let nextValue = cellValue + valueItem.from;

                if (valueItem.to) {
                    nextValue += ` - ${valueItem.to}`;
                }

                if (index < values.length - 1) {
                    nextValue += ", "
                }

                return nextValue;
            }, "");

            return cellValue;
        }

        default:
            return row[column.id];
    }
};

const getNodeTitle = (row, intl) => {
    if (row.type !== "field") {
        return intl.formatMessage(
            { id: `pfcg-view.node.${row.type}` },
            { objectId: row.id }
        );
    }
    
    if (row.orgLevel) {
        return `${row.id} (${row.orgLevel})`;
    }

    return row.id;
};

const PfcgView = ({ tree, orgLevels, onItemFieldChange, onEditItemClick, onRemoveItemClick, onExpandItemTreeClick }) => {
    const intl = useIntl();

    const columns = [
        {
            id: "node",
            title: intl.formatMessage({ id: "pfcg-view.columns.node" }),
            type: "node",
        },
        {
            id: "status",
            title: intl.formatMessage({ id: "pfcg-view.columns.status" }),
            type: "text",
        },
        {
            id: "actions",
            title: intl.formatMessage({ id: "pfcg-view.columns.actions" }),
            type: "actions",
        },
        {
            id: "value",
            title: intl.formatMessage({ id: "pfcg-view.columns.value" }),
            type: "text",
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "pfcg-view.columns.description" }),
            type: "text"
        }
    ];

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.id) {
            case "node": {
                const title = getNodeTitle(row, intl);
                
                return (
                    <PfcgViewNodeCellContent
                        open={row.open}
                        title={title}
                        leaf={row.leaf}
                        level={row.level}
                        trafficLightStatus={row.trafficLightStatus}
                        onClick={() => onItemFieldChange(row.path, "open", !row.open)}
                        onRemove={() => onRemoveItemClick(row.path)}
                        onExpandItemTree={()=>onExpandItemTreeClick(row.path)}
                    />
                );
            }

            case "actions":
                if (row.type !== "field") {
                    return (
                        <Switch
                            className="pfcg-view-switch"
                            checked={row.active}
                            onChange={(event) => onItemFieldChange(row.path, "active", event.target.checked)}
                        />
                    )
                }

                return (
                    <>
                        {
                            // row.status !== REPORT_MODELING_STATUSES.STANDARD && (
                            //     <PfcgViewEditButton onClick={() => onEditItemClick(row)} />
                            // )
                        }

                        <PfcgViewEditButton onClick={() => onEditItemClick(row)} />

                        {row.orgLevel in orgLevels && (row.status === REPORT_MODELING_STATUSES.SET || row.status === REPORT_MODELING_STATUSES.CHANGED) && (
                            <PfcgViewResetButton onClick={() => onItemFieldChange(row.path, "values", null)} />
                        )}
                    </>
                )

            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    const getCellValueInner = useCallback((row, column) => (
        getCellValue(row, column, orgLevels, intl)
    ), [orgLevels, intl]);

    return (
        <TreeTable
            className="pfcg-view"
            tree={tree}
            columns={columns}
            showFiltersActions={false}
            showPagination={false}
            getCellClassName={getCellClassName}
            getCellValue={getCellValueInner}
            createRowCellContent={createRowCellContent}
        />
    );
};

PfcgView.propTypes = {
    tree: PropTypes.arrayOf(PropTypes.object),
    orgLevels: PropTypes.object,
    onItemFieldChange: PropTypes.func,
    onEditItemClick: PropTypes.func,
    onRemoveItemClick: PropTypes.func,
    onExpandItemTreeClick: PropTypes.func
};

export default PfcgView;