import { nanoid } from "@reduxjs/toolkit";

export const createTreeNode = (item, level, parentPath, parseNode, parseItem, orgLevels) => {
    const rowId = nanoid();

    const path = parentPath
        ? [...parentPath, rowId]
        : [rowId];

    const parsedItem = parseItem ? parseItem(item) : item;

    const children = parsedItem.children?.map(
        child => createTreeNode(child, level + 1, path, parseNode, parseItem)
    );
    
    const treeNode = {
        ...parsedItem,
        children,
        path,
        level,
        rowId,
        leaf: !parsedItem.children,
        open: false,
    };

    return parseNode ? parseNode(treeNode, orgLevels) : treeNode;
};

export const findItemInTree = (itemPath, tree) => {
    let item;

    for (const currentId of itemPath) {
        const children = item ? item.children : tree;

        if (!children) {
            return;
        }

        item = children.find(child => child.rowId === currentId);
    }

    return item;
};

export const modifyItemInTreeRecursive = (item, field, value) => {
    item[field] = value;

    if (!item.children) {
        return;
    }

    for (const child of item.children) {
        modifyItemInTreeRecursive(child, field, value);
    }
};

export const modifyItemAndRecalcParentsFields = (
    children, itemPath, modifyCallback, recalcCallback, currentPathIndex=0
) => {
    const currentId = itemPath[currentPathIndex];
    const item = children?.find(child => child.rowId === currentId);

    if (!item) {
        return;
    }

    if (currentPathIndex < itemPath.length - 1) {
        modifyItemAndRecalcParentsFields(
            item.children, itemPath, modifyCallback, recalcCallback, currentPathIndex + 1
        );
        recalcCallback(item);
    } else {
        modifyCallback(item);
    }
}

export class TreeWalker{
    constructor(roots){
        this.roots = roots
    }

    walk(nodeCallback, getNodeInfo, nodePostChildrenCallback){
        this._walk(this.roots, nodeCallback, getNodeInfo, {}, nodePostChildrenCallback)
    }

    _walk(nodes, nodeCallback, getNodeInfo, parentKeyMap, nodePostChildrenCallback){
        nodes.forEach(node => {
            nodeCallback(node, parentKeyMap)

            const nodeInfo = getNodeInfo(node)

            if (!nodeInfo){
                return
            }

            const {childField, keyField, keyValue} = nodeInfo

            if (childField){
                const children = node[childField]

                if (!children){
                    return
                }

                const parentKeyMapNew = {
                    ...parentKeyMap,
                    [keyField]: keyValue
                }

                this._walk(children, nodeCallback, getNodeInfo, parentKeyMapNew, nodePostChildrenCallback)

                nodePostChildrenCallback(node, parentKeyMap)
            }
        })
    }
}