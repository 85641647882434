import React, { forwardRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, List, ListItemButton, ListItemText, Typography, IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { Button, Switch } from "../../common/buttons";
import { RightChevronIcon, ExpandDownIcon } from "../../common/icons"
import { ScrollableTabs, TabsDots } from "../../common/tabs";
import CheckboxInput from "../../common/form-controls/checkbox-input.jsx";

import { SYSTEM_TYPES, convertDateToString, getEntityTextWithIntl, getIntegrationIcon, getSystemSyncTitle, integrationReverseEntityMap } from "../../../utils/integration-utils.js";
import { RunIntegrationButton, SetUpPlanIntegrationButton } from "./integration-common.jsx";

import "./styles.less";

const IntegrationSystemBoxSmall = forwardRef(({
    integrationItem,
    open,
    editField,
    isIntegrationPending,
    runIntegrationDisabled,
    showResetStatus,
    onResetStatus,
    onOpenClick,
    onPlanClick,
    onHistoryClick,
    onStartClick,
    onPlanAllClick,
}, ref) => {
    const className = open
        ? "integration-system-box-small integration-system-box-small-open"
        : "integration-system-box-small integration-system-box-small-closed";

    return (
        <Box
            className={className}
            ref={ref}
        >
            <IntegrationSystemBoxSmallHeader
                integrationItem={integrationItem}
                showResetStatus={showResetStatus}
                onResetStatus={onResetStatus}
                onPlanClick={onPlanClick}
                onHistoryClick={onHistoryClick}
            />

            {open && (
                <IntegrationSystemBoxSmallContent
                    integrationItem={integrationItem}
                    editField={editField}
                    isIntegrationPending={isIntegrationPending}
                    runIntegrationDisabled={runIntegrationDisabled}
                    onPlanClick={onPlanClick}
                    onHistoryClick={onHistoryClick}
                    onStartClick={onStartClick}
                    onPlanAllClick={onPlanAllClick}
                />
            )}
                
            <Button
                variant="contained"
                className="integration-system-box-small-expand"
                onClick={onOpenClick}
            >
                <ExpandDownIcon />
            </Button>
        </Box>
    )
});

const IntegrationSystemBoxSmallHeader = ({
    integrationItem,
    showResetStatus,
    onResetStatus,
    onPlanClick,
    onHistoryClick,
}) => {
    const intl = useIntl();

    const lastSyncCommonTitle = getSystemSyncTitle(intl, integrationItem.common.status, integrationItem.common.lastSyncDate, "common");

    const lastSyncManualTitle = integrationItem.manualIntegration.status ?
        getSystemSyncTitle(intl, integrationItem.manualIntegration.status, integrationItem.manualIntegration.lastSyncDate, "manual")
        : null;
    
    const planTitle = intl.formatMessage({ id: "integration-details-row.btn-sheduled" });
    const resetTitle = intl.formatMessage({ id: "integration-details-row.btn-reset-status" });

    return (
        <Box className="integration-system-box-small-header">
            <IntegrationSystemBoxSmallHeaderTitle
                systemId={integrationItem.id}
                status={integrationItem.common.status}
                description={integrationItem.description}
            />

            <IntegrationSystemBoxSmallList>
                <IntegrationSystemBoxChevronItem
                    title={lastSyncCommonTitle}
                    onClick={() => onHistoryClick("common")}
                />

                {lastSyncManualTitle && (
                    <IntegrationSystemBoxChevronItem
                        title={lastSyncManualTitle}
                        onClick={() => onHistoryClick("manual_integration")}
                    />
                )}

                {showResetStatus && (
                    <IntegrationSystemBoxChevronItem
                        title={resetTitle}
                        onClick={onResetStatus}
                    />
                )}

                <IntegrationSystemBoxChevronItem
                    title={planTitle}
                    onClick={() => onPlanClick()}
                />
            </IntegrationSystemBoxSmallList>
        </Box>
    );
};

const IntegrationSystemBoxSmallHeaderTitle = ({
    systemId,
    status,
    description
}) => {
    const Icon = getIntegrationIcon(status);

    return (
        <Box className="integration-system-box-small-header-title">
            <Icon
                className="integration-system-box-header-small-title-icon"
                size="20px"
            />
            <Typography className="integration-system-box-small-header-title-system">
                {systemId}
            </Typography>
            <Typography className="integration-system-box-small-header-title-description">
                {description}
            </Typography>
        </Box>
    );
};

const IntegrationSystemBoxSmallCheckboxInput = ({
    className="",
    label,
    checked,
    onChange,
    disabled
}) => {
    return (
        <CheckboxInput
            className={`integration-system-box-small-checkbox-input ${className}`}
            label={label}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    );
}

const IntegrationSystemBoxSmallList = ({ className="", ...props }) => {
    return (
        <List
            className={`integration-system-box-small-list ${className}`}
            {...props}
        />
    )
};

const IntegrationSystemBoxItem = (props) => (
    <ListItemButton
        className="integration-system-box-small-item"
        {...props}
    />
);

const IntegrationSystemBoxItemText = (props) => (
    <ListItemText
        className="integration-system-box-small-item-title"
        {...props}
    />
);

const IntegrationSystemBoxChevronButton = ({ variant="right", onClick }) => {
    const baseClassName = "integration-system-box-small-chevron-button";
    const variantClassName = `${baseClassName}-${variant}`;
    const className = `${baseClassName} ${variantClassName}`;

    return (
        <IconButton
            className={className}
            onClick={onClick}
        >
            <RightChevronIcon />
        </IconButton>
    );
};

const IntegrationSystemBoxChevronItem = ({
    title,
    onClick
}) => {
    return (
        <IntegrationSystemBoxItem onClick={onClick}>
            <IntegrationSystemBoxItemText>
                {title}
            </IntegrationSystemBoxItemText>
            
            <IntegrationSystemBoxChevronButton
                variant="right"
            />
        </IntegrationSystemBoxItem>
    );
};

const IntegrationSystemBoxSwitchItem = ({
    title,
    checked,
    onClick
}) => {
    return (
        <IntegrationSystemBoxItem onClick={onClick}>
            <IntegrationSystemBoxItemText>
                {title}
            </IntegrationSystemBoxItemText>
            
            <Switch checked={checked} />
        </IntegrationSystemBoxItem>
    );
};

const IntegrationSystemBoxSmallContent = ({
    integrationItem,
    editField,
    isIntegrationPending,
    runIntegrationDisabled,
    onPlanClick,
    onHistoryClick,
    onStartClick,
    onPlanAllClick,
}) => {

    const systemType = integrationItem.systemType
    const isSAPSystem = systemType === SYSTEM_TYPES.SAP 
    const initialState = isSAPSystem ? "profile" : "authorityDetail"
    const planType = isSAPSystem ? "repository_objects" : "common"

    const intl = useIntl();
    const [tab, setTab] = useState(initialState);

    const createTab = (entity) => ({
        id: entity,
        label: (
            <IntegrationSystemBoxSmallCheckboxInput
                label={getEntityTextWithIntl(intl, entity)}
                checked={integrationItem[entity]?.checked}
                onChange={() => editField(entity, "checked", !integrationItem[entity]?.checked)}
                disabled={isIntegrationPending}
            />
        ),
    });

    const tabsSAP = [
        createTab("profile"),
        createTab("role"),
        createTab("employee"),
    ];

    const tabs1C = [
        createTab("authorityDetail"),
        createTab("metadataObject"),
        createTab("employee1C"),
        createTab("role1C")
    ];

    const tabs = isSAPSystem ? tabsSAP : tabs1C

    return (
        <Box className="integration-system-box-small-content">
            {isSAPSystem && <IntegrationSystemBoxSmallCheckboxInput
                label={getEntityTextWithIntl(intl, "permissionDetail")}
                checked={integrationItem.permissionDetail.checked}
                onChange={() => editField("permissionDetail", "checked", !integrationItem.permissionDetail.checked)}
                disabled={isIntegrationPending}
            />
            }

            {isSAPSystem && <IntegrationSystemBoxSmallContentObjectSettings
                className="integration-system-box-small-list-permission-detail"
                entity="permissionDetail"
                entityData={integrationItem.permissionDetail}
                editField={editField}
                onPlanClick={() => onPlanClick("permission_details")}
                onHistoryClick={() => onHistoryClick("permission_details")}
            />
            }

            {isSAPSystem && <Typography className="integration-system-box-small-content-subtitle">
                <FormattedMessage id="integration-schedule-page.entity.repository-objects.title"/>
            </Typography>
            }

            <IntegrationSystemBoxSmallTabs
                tabs={tabs}
                value={tab}
                onChange={setTab}
            />

            <IntegrationSystemBoxSmallContentObjectSettings
                entity={tab}
                entityData={integrationItem[tab]}
                editField={editField}
                onPlanClick={() => onPlanClick(planType)}
                onHistoryClick={() => onHistoryClick(integrationReverseEntityMap[tab])}
            />

            <TabsDots
                tabs={tabs}
                value={tab}
                onChange={setTab}
            />

            <IntegrationSystemBoxSmallActions
                runIntegrationDisabled={runIntegrationDisabled}
                onPlanClick={onPlanAllClick}
                onStartClick={onStartClick}
            />
        </Box>
    );
};

const IntegrationSystemBoxSmallTabs = ({ className="", tabs, value, onChange }) => {
    return (
        <ScrollableTabs
            className={`${className} integration-system-box-small-tabs`}
            tabs={tabs}
            value={value}
            onChange={onChange}
            oneVisibleTab={true}
        />
    );
};

const IntegrationSystemBoxSmallContentObjectSettings = ({
    className="",
    entity,
    editField,
    entityData,
    onPlanClick,
    onHistoryClick,
}) => {
    const intl = useIntl();

    const lastSyncTitle = getSystemSyncTitle(intl, entityData.status, entityData.lastSyncDate, "entity");
    const lastSyncDate = convertDateToString(intl, entityData.lastSyncDate);
    const planTitle = intl.formatMessage({ id: "integration-details-row.btn-sheduled" });
    const syncFutureTitle = intl.formatMessage({ id: "integration-details-row.sync-future.text" });
    const loadDeltaTitle = intl.formatMessage({ id: "integration-details-row.load-delta.text" });

    return (
        <IntegrationSystemBoxSmallList className={className}>
            <IntegrationSystemBoxChevronItem
                title={`${lastSyncTitle} ${lastSyncDate}`}
                onClick={onHistoryClick}
            />

            <IntegrationSystemBoxChevronItem
                title={planTitle}
                onClick={onPlanClick}
            />

            <IntegrationSystemBoxSwitchItem
                title={loadDeltaTitle}
                checked={entityData.loadDelta}
                onClick={() => editField(entity, "loadDelta", !entityData.loadDelta)}
            />

            <IntegrationSystemBoxSwitchItem
                title={syncFutureTitle}
                checked={entityData.syncFuture}
                onClick={() => editField(entity, "syncFuture", !entityData.syncFuture)}
            />
        </IntegrationSystemBoxSmallList>
    );
};

const IntegrationSystemBoxSmallActions = ({ runIntegrationDisabled, onStartClick, onPlanClick }) => {
    return (
        <Box className="integration-system-box-small-actions">
            <RunIntegrationButton
                disabled={runIntegrationDisabled}
                onClick={onStartClick}
            />
            <SetUpPlanIntegrationButton
                onClick={onPlanClick}
            />
        </Box>
    );
};

IntegrationSystemBoxSmall.propTypes = {
    integrationItem: PropTypes.object,
    open: PropTypes.bool,
    editField: PropTypes.func,
    isIntegrationPending: PropTypes.bool,
    runIntegrationDisabled: PropTypes.bool,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onOpenClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onStartClick: PropTypes.func,
    onPlanAllClick: PropTypes.func
};

IntegrationSystemBoxSmallHeader.propTypes = {
    integrationItem: PropTypes.object,
    showResetStatus: PropTypes.bool,
    onResetStatus: PropTypes.func,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func
};

IntegrationSystemBoxSmallHeaderTitle.propTypes = {
    systemId: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string
};

IntegrationSystemBoxSmallCheckboxInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

IntegrationSystemBoxSmallList.propTypes = {
    className: PropTypes.string
};

IntegrationSystemBoxChevronButton.propTypes = {
    variant: PropTypes.string,
    onClick: PropTypes.func
};

IntegrationSystemBoxChevronItem.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func
};

IntegrationSystemBoxSwitchItem.propTypes = {
    title: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func
};

IntegrationSystemBoxSmallContent.propTypes = {
    integrationItem: PropTypes.object,
    editField: PropTypes.func,
    isIntegrationPending: PropTypes.bool,
    runIntegrationDisabled: PropTypes.bool,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onStartClick: PropTypes.func,
    onPlanAllClick: PropTypes.func,
};

IntegrationSystemBoxSmallTabs.propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func
};

IntegrationSystemBoxSmallContentObjectSettings.propTypes = {
    className: PropTypes.string,
    entity: PropTypes.string,
    editField: PropTypes.func,
    entityData: PropTypes.object,
    onPlanClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
};

IntegrationSystemBoxSmallActions.propTypes = {
    runIntegrationDisabled: PropTypes.bool,
    onStartClick: PropTypes.func,
    onPlanClick: PropTypes.func
};

export default IntegrationSystemBoxSmall;